<template>
<div class="mx-auto max-w-2xl">
    <div class="flex flex-col md:flex-row items-center justify-between">
        <h2 class="text-2xl font-bold tracking-tight">AA Score™ Ratings</h2>
        <input v-model="search" class="w-full md:w-auto mt-4 md:mt-0 form-input bg-gray-800 border-none text-lg" placeholder="Search coin">
    </div>
    <div v-for="rating in searchedRatings" class="bg-gray-800 p-4 rounded-lg mt-5" :key="rating.coin.ticker + rating.rating.time">
        <div class="flex items-center">
            <div>
                <p class="text-lg">{{ rating.coin.name }}</p>
                <p class="text-lg text-gray-500">{{ rating.coin.ticker }}</p>
            </div>
            <div class="ml-auto">
                <!-- <p class="text-sm text-gray-300 text-right">{{ rating.rating.time | timeWithinToday }}</p> -->
                <div class="text-right mt-1">
                    <span :class="aaScore(rating.rating.score).pill" class="pill text-sm py-1">
                        {{ aaScore(rating.rating.score).text }}
                    </span>
                    {{ rating.rating.score | decimals(1,1) }}
                </div>
            </div>
        </div>
        <div class="flex items-center justify-between mt-3">
            <span class="text-sm text-gray-400">Rated on {{ rating.rating.time | timeWithinToday }}</span>
            <span class="text-sm text-gray-400">
                {{ rating.coin.ticker }} was at ${{ rating.rating.price_usd | usdCryptoPrice }}<span v-if="ratingWithin24Hours(rating)">, now at ${{ rating.coin.price_usd | usdCryptoPrice }}</span>
            </span>
        </div>
    </div>
    <div v-if="searchedRatings.length == 0" class="mt-10 text-gray-400">
        <span>No results for "{{ search }}". Must be an exact match on coin name or ticker.</span>
    </div>
</div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import tableMixin from './mixins/table.js'
export default {
    mixins: [tableMixin],
    data () {
        return {
            selectedCurrencies: ["USD"],
            editableRatings: JSON.parse(JSON.stringify(this.ratings)),
            dataLastFetched: '',
            search: ''
        }
    },
    props: ['ratings'],
    mounted () {
        var self = this
        setInterval(function() {
            self.updateData()
        }, 60000)
    },
    computed: {
        searchedRatings() {
            return this.editableRatings.filter(rating => (this.search == '' || rating.coin.ticker.toLowerCase() == this.search.toLowerCase() || rating.coin.name.toLowerCase() == this.search.toLowerCase()))
        }
    },
    methods: {
        ratingWithin24Hours(rating) {
            return moment().diff(moment(rating.rating.time), 'hours') <= 24
        },
        returnSinceRating(rating) {
          return 100 * (rating.coin.price_usd / rating.rating.price_usd - 1)
        },
        aaScore(value) {
            if (value < 20) {
                return { text: "Very Bearish", pill: "pill-red" }
            }
            else if (value < 40) {
                return { text: "Bearish", pill: "pill-red" }
            }
            else if (value < 60) {
                return { text: "Neutral", pill: "pill-yellow" }
            }
            else if (value < 80) {
                return { text: "Bullish", pill: "pill-green" }
            }
            else {
                return { text: "Very Bullish", pill: "pill-green" }
            }
        },
        updateData() {
            var self = this
            axios
            .get('/ratings')
            .then((response) => {
                self.editableRatings = response.data
                // self.updateDataLastFetched()
            })
            .catch((error) => {
                console.log(error)
                // self.updateDataLastFetched()
            })
        },
    }
}
</script>