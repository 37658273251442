<template>
<div>
    <!-- <coins-leaderboards :coins="editableCoins"></coins-leaderboards> -->
    <div class="flex flex-col mt-2.5 mx-4 md:mx-0">
        <div class="ml-auto" v-if="showFilterIcon" @click="showFilters = !showFilters">
            <svg xmlns="http://www.w3.org/2000/svg" class="w-3 h-3 text-gray-200 inline ml-0" fill="currentColor" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z" />
            </svg>
            <span class="text-xs mb-1 text-gray-200 -ml-1">Filters</span>
        </div>
        <div class="md:flex md:justify-between relative z-40 md:space-x-4 md:space-y-0 space-y-4" v-if="showFilters">
            <div class="md:flex md:flex-row md:space-x-4 md:space-y-0 space-y-4">
              <div>
             <EasyModeToggle left-text="Easy Mode" componentName="Radar" @change="setEasyMode($event)" />
             </div>
            <div class="">
                <input v-model="search" id="search" class="w-full md:w-56 form-input bg-gray-800 border-none text-xs" placeholder="Search coins (/)">
            </div>
            <div class="sm:flex sm:flex-col items-center select-none ml-0 cursor-pointer" @click="toggleShowFavorites()">
                <span class="text-xs md:ml-1 md:mb-2" :class="onlyShowFavorites ? 'text-yellow-400' : 'text-gray-400'">Filter favorites</span>
                <svg v-if="onlyShowFavorites" class="w-6 h-6 text-yellow-400 inline md:block" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
                <svg v-else class="w-6 h-6 text-gray-500 inline md:block" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
            </div>
            </div>
           <div class="md:flex md:flex-row md:space-x-4 md:space-y-0 space-y-4">
            <div class="sm:flex flex-col items-center" v-if="!easyMode">
                <div>
                    <svg class="inline w-3 h-3 cursor-pointer" @mouseenter="showTraderModeTooltip = true" @mouseleave="showTraderModeTooltip = false" fill="currentColor" viewBox="0 0 20 20"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd"></path></svg>
                    <span class="text-xs mb-1 text-gray-400 -ml-1">Trader Modes</span>
                </div>
                <span class="z-0 inline-flex shadow-sm">
                    <div v-for="(mode,idx) in traderModes" :key="idx">
                    <span class="cursor-pointer relative inline-flex items-center px-2 lg:px-4 py-1 rounded-md border text-sm leading-5 font-medium focus:z-10 focus:outline-none transition ease-in-out duration-150 ml-1"
                        :class=" selectedTraderMode === mode ? 'border-blue-900 bg-blue-900 text-blue-200' : 'border-gray-700 bg-gray-900 text-white'"
                        @click="toggleTraderMode(mode)">
                        {{ mode }}
                    </span>
                    </div>
                    <div v-if="showTraderModeTooltip" class="p-3 rounded absolute bg-gray-900 border border-gray-700 text-xs mt-3 normal-case text-left w-1/5">
                        Each Trader Mode displays a different set of metrics. <br/>
                        <span class="font-bold underline">All</span> shows all the metrics. <br/>
                        <span class="font-bold underline">Technical</span> mode displays Elder Impulse System, AA Score Bullish, and Trading Activity. <br/>
                        <span class="font-bold underline">Sentiment</span> mode displays Long/Short Term Sentiment, 1HR Proj Range, Social Activity, and AA Score<br/>
                        <span class="font-bold underline">Long/Short</span> mode displays Trading Activity, Elder Impulse System, and AA Score.
                    </div>
                </span>
            </div>
            <div class="sm:flex flex-col items-center">
                <span class="text-xs mb-1 text-gray-400">Price Metrics</span>
                <span class="ml-auto z-0 inline-flex shadow-sm">
                    <span class="cursor-pointer relative inline-flex items-center px-4 py-1 rounded-md border text-sm leading-5 font-medium focus:z-10 focus:outline-none transition ease-in-out duration-150" :class="selectedCurrencies.includes('USD') ? 'border-blue-900 bg-blue-900 text-blue-200' : 'border-gray-700 bg-gray-900 text-white'" @click="toggleCurrency('USD')">
                        USD
                    </span>
                    <span class="ml-1 cursor-pointer relative inline-flex items-center px-4 py-1 rounded-md border text-sm leading-5 font-medium focus:z-10 focus:outline-none transition ease-in-out duration-150" :class="selectedCurrencies.includes('BTC') ? 'border-blue-900 bg-blue-900 text-blue-200' : 'border-gray-700 bg-gray-900 text-white'" @click="toggleCurrency('BTC')">
                        BTC
                    </span>
                </span>
            </div>
            <div class="flex md:flex-col">
            <div class="hidden md:block sm:ml-auto sm:self-end text-xs text-gray-400">
                <svg class="-mt-px text-gray-500 inline w-4 h-4" fill="currentColor" viewBox="0 0 20 20"><path fill-rule="evenodd" d="M11.3 1.046A1 1 0 0112 2v5h4a1 1 0 01.82 1.573l-7 10A1 1 0 018 18v-5H4a1 1 0 01-.82-1.573l7-10a1 1 0 011.12-.38z" clip-rule="evenodd"></path></svg>
                Data updated {{ dataLastFetched }}
            </div>
             <div class="hidden md:block text-xs text-gray-400 text-right pl-2">powered by <a href="https://thetie.io" target="_blank" class="text-gray-200">The TIE</a></div>
            </div>
            </div>
            <!-- <div class="hidden sm:flex flex-col items-center ml-10">
                <span class="text-xs mb-2 text-gray-400">Activity rankings (key)</span>
                <div class="flex items-center select-none">
                    <span>💩</span>
                    <span class="text-gray-500">&#8226; <span>👎</span></span>
                    <span class="text-gray-500">&#8226; <span class="text-xs text-white">Normal&nbsp;</span></span>
                    <span class="text-gray-500">&#8226; <span>👍</span></span>
                    <span class="text-gray-500">&#8226; <span>🔥</span></span>

                </div>
            </div> -->
        </div>
    <div class="-mb-2 mt-2 py-2 sm:-mx-6 sm:px-6 overflow-x-auto sm:overflow-visible">
        <div class="align-middle inline-block min-w-full shadow sm:rounded-lg border-b border-gray-800">
        <table class="min-w-full table-auto">
            <thead>
            <tr>
                <th width="30" class="text-center sticky top-0 select-none">
                    Rank
                </th>
                <th v-for="(attribute, id) in attributes" :class="[attribute.align || 'text-right', attribute.name == 'Coin' ? 'sticky left-0 sm:top-0 z-50' : 'sm:sticky sm:top-0 relative']" :key="id" v-if="!attribute.currency || selectedCurrencies.includes(attribute.currency)" class="cursor-pointer select-none" :width="attribute.width">
                    <div class="flex items-center"  @click="toggleColumnSort(id)" :class="[attribute.align || 'justify-end']">
                        <svg v-if="columnDirection == 'desc' && id == sortColumn" class="w-4 h-4 flex-shrink-0 mr-1" fill="currentColor" viewBox="0 0 20 20"><path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                        <svg v-if="columnDirection == 'asc' && id == sortColumn" class="w-4 h-4 flex-shrink-0 mr-1" fill="currentColor" viewBox="0 0 20 20"><path fill-rule="evenodd" d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z" clip-rule="evenodd"></path></svg>
                        <svg v-if="attribute.tooltip" @mouseenter="activeAttributeTooltip = attribute" @mouseleave="activeAttributeTooltip = ''" class="w-4 h-4 flex-shrink-0 md:inline mr-1 hidden" fill="currentColor" viewBox="0 0 20 20"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd"></path></svg>
                        <span>{{ attribute.name }}</span>
                    </div>
                    <div v-if="activeAttributeTooltip == attribute && attribute.tooltip" class="p-3 rounded absolute bg-gray-900 border border-gray-700 text-xs mt-8 normal-case text-left" style="width: 400px; margin-left: -250px; z-index: 1000" v-html="attribute.tooltip">
                    </div>
                    <div v-if="attribute.displayOverview" @mouseenter="activeOverviewAttribute = attribute" @mouseleave="activeOverviewAttribute = ''" >
                        <span class="text-green-400">{{ coinCount(editableCoins, "Bullish", attribute.name, true) }}</span>/
                        <span class="inline-block -ml-2">{{ coinCount(editableCoins, "Neutral", attribute.name) }}/</span>
                        <span class="text-red-500 inline-block -ml-2">{{ coinCount(editableCoins, "Bearish", attribute.name, true) }}</span>
                    </div>
                    <CoinOverviewModal :class="attribute != attributes.score && selectedTraderMode == 'All' ? '':'right-1'" class="rounded-lg absolute bg-gray-900 border border-gray-700 text-xs mt-5 normal-case text-left w-48 sm:w-64" v-if="activeOverviewAttribute == attribute" :coins="editableCoins" :metric="attribute.name"></CoinOverviewModal>
                    <div v-if="attribute.activityOverview" @mouseenter="activeActivityAttribute = attribute" @mouseleave="activeActivityAttribute = ''" >
                        <span class="text-green-400">{{ coinCount(editableCoins, "👍", attribute.name, true) + coinCount(editableCoins, "🔥", attribute.name, true) }}</span>/
                        <span class="inline-block -ml-2">{{ coinCount(editableCoins, "Normal", attribute.name) }}/</span>
                        <span class="text-red-500 inline-block -ml-2">{{ coinCount(editableCoins, "💩", attribute.name, true) + coinCount(editableCoins, "👎", attribute.name, true) }}</span>
                    </div>
                    <CoinOverviewModal :class="attribute != attributes.score && selectedTraderMode == 'All' ? '':'right-1'" class="rounded-lg absolute bg-gray-900 border border-gray-700 text-xs mt-5 normal-case text-left w-48 sm:w-64" v-if="activeActivityAttribute == attribute" :activityOverview="attribute.activityOverview" :coins="editableCoins" :metric="attribute.name"></CoinOverviewModal>
                </th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(coin, ix) in filteredSortedCoins" :key="coin.id">
                <td class="text-center">
                    <span class="text-gray-400">{{ columnDirection == 'asc' ? sortedCoins.length - ix : ix + 1 }}</span>
                </td>
                <td class="sticky left-0 sm:static bg-gray-800 sm:bg-transparent">
                    <div class="flex items-center">
                        <svg v-if="coin.is_favorite" @click="unfavoriteCoin(coin)" class="w-4 h-4 mr-2 text-yellow-400 cursor-pointer" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
                        <svg v-else @click="favoriteCoin(coin)" class="w-4 h-4 mr-2 text-gray-500 cursor-pointer" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
                        <a :href="`/coins/${coin.ticker}`">
                            <div>
                                <div class="text-sm leading-5 font-medium">{{ coin.name | truncate(12) }}</div>
                                <div class="text-sm leading-5 text-gray-400">{{ coin.ticker }}</div>
                            </div>
                        </a>
                    </div>
                </td>
                <td class="text-right" v-if="selectedCurrencies.includes('USD')">
                    ${{ coin.price_usd | usdCryptoPrice }}
                </td>
                <td class="text-right" v-if="selectedCurrencies.includes('BTC')">
                    <span v-if="coin.price_btc > 0.0001">
                        {{ coin.price_btc | decimals(6,6) }}
                    </span>
                    <span v-else>
                        {{ coin.price_btc * 100000000 | decimals(0,0) }} <span class="text-xs">sats</span>
                    </span>
                </td>
                <td class="text-right" v-if="selectedCurrencies.includes('USD')">
                    ${{ coin.market_cap | formatNumber(2) }}
                </td>
                <td class="text-right" :class="$root.textColor(coin.price_change_usd_24hr)" v-if="selectedCurrencies.includes('USD')"> {{ coin.price_change_usd_24hr | decimals(2,2) | percentage }}
                </td>
                <td class="text-right" :class="$root.textColor(coin.price_change_btc_24hr)" v-if="selectedCurrencies.includes('BTC')">  {{ coin.price_change_btc_24hr | decimals(2,2) | percentage }}
                </td>
                <td class="text-right" v-if="(allAttributes.short_term_sentiment.traderMode && (selectedTraderMode.includes('Sentiment') || selectedTraderMode.includes('All'))) || easyMode">
                    <span v-if="sufficientTweets(coin)" class="pill" :class="sentiment(coin.short_term_sentiment).pill">{{ sentiment(coin.short_term_sentiment).text }}</span>
                    <span v-else class="text-xs text-gray-500 leading-tight">not enough<br>tweets</span>
                </td>
                <td class="text-right" v-if="(allAttributes.long_term_sentiment.traderMode && (selectedTraderMode.includes('Sentiment') || selectedTraderMode.includes('All'))) && !easyMode">
                    <span v-if="sufficientTweets(coin)" class="pill" :class="sentiment(coin.long_term_sentiment).pill">{{ sentiment(coin.long_term_sentiment).text }}</span>
                    <span v-else class="text-xs text-gray-500 leading-tight">not enough<br>tweets</span>
                </td>
                <td class="text-right" v-if="(allAttributes.elder_impulse_signal.traderMode && (selectedTraderMode.includes('Technical') || selectedTraderMode.includes('All'))) || easyMode">
                    <span class="pill" :class="elderImpulse(coin.elder_impulse_signal).pill">{{ elderImpulse(coin.elder_impulse_signal).text }}</span>
                </td>
                <td class="text-right" v-if="(allAttributes.elder_impulse_signal_hourly.traderMode && (selectedTraderMode.includes('Long/Short') || selectedTraderMode.includes('All'))) || easyMode">
                    <span class="pill" :class="elderImpulse(coin.elder_impulse_signal_hourly).pill">{{ elderImpulse(coin.elder_impulse_signal_hourly).text }}</span>
                </td>
                <td class="text-right py-2" v-if="(allAttributes.price_prediction_percent.traderMode && (selectedTraderMode.includes('Sentiment') || selectedTraderMode.includes('All')) && !easyMode) && selectedCurrencies.includes('USD')">
                    <template v-if="coin.price_prediction_percent && coin.price_prediction_percent < 40 && coin.price_prediction_percent_lower > -50 && coin.price_prediction_percent_upper < 100">
                        <p class="text-xxs">
                            <span class="text-gray-400">
                                Upper:
                            </span>
                            <span :class="$root.textColor(coin.price_prediction_percent_upper)">
                                {{ coin.price_prediction_percent_upper | decimals(2,2) | percentage}}
                            </span>
                        </p>
                        <p :class="$root.textColor(coin.price_prediction_percent)">
                            {{ coin.price_prediction_percent | decimals(2,2) | percentage }}
                        </p>
                        <p class="text-xxs ">
                            <span class="text-gray-400">
                                Lower:
                            </span>
                            <span :class="$root.textColor(coin.price_prediction_percent_lower)">
                                {{ coin.price_prediction_percent_lower | decimals(2,2) | percentage}}
                            </span>
                        </p>
                    </template>
                    <span v-else class="text-xs text-gray-400">n/a</span>
                </td>
                <td class="text-right" v-if="(allAttributes.relative_tweet_volume.traderMode && (selectedTraderMode.includes('Sentiment') || selectedTraderMode.includes('All'))) && !easyMode">
                    <div v-if="coin.relative_tweet_volume != 0">
                        <span :class="relativeActivity(coin.relative_tweet_volume - 1) == 'Normal' ? 'pill pill-yellow' : 'text-2xl'">{{ relativeActivity(coin.relative_tweet_volume - 1) }}</span><br>
                        <span class="text-xs" :class="$root.textColor(coin.relative_tweet_volume - 1)">{{ 100 * (coin.relative_tweet_volume - 1) | decimals(2,2) | percentage }}</span>
                    </div>
                </td>
                <td class="text-right" v-if="allAttributes.relative_trading_volume.traderMode && (selectedTraderMode.includes('Technical') || selectedTraderMode.includes('Long/Short') || selectedTraderMode.includes('All')) && !easyMode">
                    <div v-if="coin.relative_trading_volume != 0">
                        <span :class="relativeActivity(coin.relative_trading_volume - 1) == 'Normal' ? 'pill pill-yellow' : 'text-2xl'">{{ relativeActivity(coin.relative_trading_volume - 1) }}</span><br>
                        <span class="text-xs" :class="$root.textColor(coin.relative_trading_volume - 1)">{{ 100 * (coin.relative_trading_volume - 1) | decimals(2,2) | percentage }}</span>
                    </div>
                </td>
                <td class="text-right">
                    <span v-if="coin.score" class="pill" :class="aaScore(coin.score).pill">{{ aaScore(coin.score).text }}</span>
                    <span v-if="coin.score" class="">
                        {{ coin.score | decimals(1,1) }}
                    </span>
                    <span v-else class="text-xs text-gray-500">not scored</span>
                </td>
            </tr>
            </tbody>
        </table>
        </div>
    </div>
    </div>
</div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import tableMixin from './mixins/table.js'
import globalMixin from './mixins/global.js'
import CoinsLeaderboards from './coins_leaderboards'
import EasyModeToggle from "./easy_mode_toggle.vue";
import CoinOverviewModal from "./coin_overview_modal.vue"
export default {
    mixins: [tableMixin, globalMixin],
    components: {
        CoinsLeaderboards,
        EasyModeToggle,
        CoinOverviewModal
    },
    data () {
        return {
            activeAttributeTooltip: '',
            activeOverviewAttribute: '',
            activeActivityAttribute: '',
            allAttributes: {
                name: { name: "Coin", easyMode:true, align: "text-left" },
                price_usd: { name: "Price (USD)", easyMode:true, currency: "USD" },
                price_btc: { name: "Price (BTC)", easyMode:true, currency: "BTC"},
                market_cap: { name: "Market Cap (USD)", currency: "USD" , easyMode:true},
                price_change_usd_24hr: { name: "24hr Change (USD)", easyMode: true, currency: "USD" },
                price_change_btc_24hr: { name: "24hr Change (BTC)", easyMode: true, currency: "BTC" },
                short_term_sentiment: { name: "Short Term Sentiment", traderMode:["Sentiment"], easyMode:true, sentiment: true, displayOverview: true, tooltip: "Short Term Sentiment measures how bullish or bearish people are on Twitter about a coin in the last 1 hour, compared to the last 24 hours. This metric moves faster than the Long Term Sentiment, so is more appropriate for intra-day trading. Learn more on the Support Videos page." },
                long_term_sentiment: { name: "Long Term Sentiment",traderMode:["Sentiment"], easyMode:false, sentiment: true, displayOverview: true, tooltip: "Long Term Sentiment measures how bullish or bearish people are on Twitter about a coin in the last 24 hours, compared to the last 20 days. This metric is a little more stable than Short Term Sentiment, making it appropriate for swing trading. Learn more on the Support Videos page." },
                elder_impulse_signal: { name: "Elder Impulse (Daily)", traderMode:["Technical"], easyMode:true, displayOverview: true, tooltip: "The Elder Impulse System is a popular trend-following and momentum-based trading strategy that is based off of an exponential moving average and a MACD. This is a customized version, built to isolate signals based on the 20-day and 50-day averages. (Please note: Elder Impulse System only provides 3 signals - Bearish / Neutral / Bullish)" },
                elder_impulse_signal_hourly: { name: "Elder Impulse (Hourly)", traderMode:["Long/Short"], easyMode:true, displayOverview: true, tooltip: "The Elder Impulse System is a popular trend-following and momentum-based trading strategy that is based off of an exponential moving average and a MACD. This is a customized version, built to isolate signals based on the 20-hour and 50-hour averages. The Hourly version is meant for shorter-term traders than the Daily. (Please note: Elder Impulse System only provides 3 signals - Bearish / Neutral / Bullish)" },
                // hype_to_activity: { name: "Hype Level", tooltip: "Hype Level measures how much Twitter conversation there is on a coin relative to its trading volume. A coin is 'overhyped' if it has way more conversation than actual trading volume, relative to other coins. It is 'underhyped' if there's more trading volume than conversation, relative. Learn more on the Support Videos page." },
                price_prediction_percent: { name: "1hr Proj. Range (USD)", traderMode:["Sentiment"], easyMode:false, currency: "USD", tooltip: "1hr Projection Range uses a combination of sentiment, sentiment momentum, price momentum, and volatility, to estimate the range we expect the coin to fall in 1 hour from now. The center number is the middle of the range.<br><br>Historically, coins have fallen within this range over 90% of the time. This metric becomes notable when you see a large tilt into positive or negative territory. Learn more on the Support Videos page." },
                relative_tweet_volume: { name: "Social Activity", traderMode:["Sentiment"],  easyMode:false, activityOverview: true, tooltip: "Social Activity compares the tweet volume today vs. the 30 day average daily tweet volume to see how active conversation currently is around a coin.<br><br>This is a 5 point scale, which you can see a little to the right at the top of this table. A 🔥 or 💩 rating may indicate a coin is reaching an extreme." },
                relative_trading_volume: { name: "Trading Activity", traderMode:["Technical","Long/Short"], activityOverview: true, easyMode:false, tooltip: "Trading Activity compares the trading volume today vs. the 30 day average daily trading volume to see how actively a coin is trading relative to its average.<br><br>This is a 5 point scale, which you can see a little to the right at the top of this table. A 🔥 or 💩 rating may indicate a coin is reaching an extreme." },
                score: { name: "AA Score™", easyMode:true, displayOverview: true, tooltip: "AA Score is a proprietary metric developed by Altcoin Alert. It's algorithmically generated to estimate the strength or weakness of a coin over the next 48 hours on a scale of 0-100. When a coin is rated 'Very Bullish' (> 80) or 'Very Bearish' (< 20), an alert will immediately be sent in the Slack group.<br><br>While this rating is quite accurate, it is not going to be 100% accurate all the time. So let it help you spot possible opportunity, but not be the only piece of your strategy. Learn more about how it works on the Support Videos page." },
            },
            selectedCurrencies: ["USD"],
            editableCoins: JSON.parse(JSON.stringify(this.coins)),
            dataLastFetched: '',
            onlyShowFavorites: JSON.parse(window.localStorage.getItem("onlyShowFavorites") || false),
            search: '',
            easyMode: false,
            traderModes: ["All", "Technical", "Sentiment", "Long/Short"],
            selectedTraderMode: "All",
            showTraderModeTooltip: false,
            windowWidth: window.innerWidth,
            showFilters: true,
            showFilterIcon: false,
        }
    },
    props: ['coins'],
    mounted () {
        var self = this
        self.updateDataLastFetched()
        document.addEventListener("keyup", self.seachBarFocus)
        setInterval(function() {
            self.updateData()
        }, 60000)

        //enable below lines to hide filters on mobile
        // if(self.mobileDevice){
        //     self.showFilterIcon = true
        //     self.showFilters = false
        // }
    },
    beforeDestroy(){
        document.removeEventListener("keyup", this.seachBarFocus)
    },
    computed: {
        attributes() {
            if(!this.easyMode) {
                if(this.selectedTraderMode == "All") {
                    return this.allAttributes
                }
                else {
                return Object.fromEntries(
                        Object.entries(this.allAttributes).filter(([key, val]) => {
                            return val.traderMode == undefined || val.traderMode.includes(this.selectedTraderMode)
                        }));
                }
            }
            else {
                return Object.fromEntries(
                            Object.entries(this.allAttributes).filter(([key, val])=> val.easyMode)
                        );
            }
        },
        sortedCoins() {
            return this.sortByAttribute(this.editableCoins, this.sortColumn, this.columnDirection, this.allAttributes[this.sortColumn].sentiment || false).filter(c => this.onlyShowFavorites ? c.is_favorite : true)
        },
        filteredSortedCoins() {
            return this.sortedCoins
            .filter(c => this.onlyShowFavorites ? c.is_favorite : true)
            .filter(c => this.search ? this.matchesSearch(c) : true)
        },
        mobileDevice() {
            return window.innerWidth <= 640
        }
    },
    methods: {
        seachBarFocus(e){
            if (e.code == "Slash") {
                document.getElementById("search").focus();
            }
            if(e.code == "Escape") {
                this.search = ''
                document.getElementById("search").blur();
            }
        },
        matchesSearch(coin) {
            let query = this.search.toLowerCase()
            return coin.name.toLowerCase().includes(query) || coin.ticker.toLowerCase().includes(query)
        },
        updateDataLastFetched() {
            this.dataLastFetched = moment(this.editableCoins[0].updated_at).fromNow()
        },
        toggleCurrency(currency) {
            if (this.selectedCurrencies.includes(currency)) {
                this.selectedCurrencies = this.selectedCurrencies.filter((cur) => cur != currency)
            }
            else {
                this.selectedCurrencies.push(currency)
            }
        },
        updateData() {
            var self = this
            axios
            .get('/coins')
            .then((response) => {
                self.editableCoins = response.data
                self.updateDataLastFetched()
            })
            .catch((error) => {
                console.log(error)
                self.updateDataLastFetched()
            })
        },
        favoriteCoin(coin) {
            coin.is_favorite = !coin.is_favorite
            axios
            .post('/favorite_coins', { coin_id: coin.id })
            .then(response => {
                })
            .catch(error => {
                coin.is_favorite = !coin.is_favorite
                console.log(error)
            })
        },
        unfavoriteCoin(coin) {
            coin.is_favorite = !coin.is_favorite
            axios
            .delete('/favorite_coins/0', { params: { coin_id: coin.id } })
            .then(response => {
            })
            .catch(error => {
                console.log(error)
            })
        },
        toggleShowFavorites() {
            this.onlyShowFavorites = !this.onlyShowFavorites
            window.localStorage.setItem("onlyShowFavorites", JSON.stringify(this.onlyShowFavorites))
        },
        setEasyMode(easyModeValue) {
            this.easyMode = easyModeValue
            localStorage.setItem('easyMode', this.easyMode);
            if(this.easyMode) {
                this.selectedTraderMode = "All"
            }
        },
        toggleTraderMode(traderMode) {
            switch (traderMode) {
                case "All":
                    this.selectedTraderMode = "All"
                    break;
                case "Technical":
                    this.selectedTraderMode = "Technical"
                    break;
                case "Sentiment":
                    this.selectedTraderMode = "Sentiment"
                    break;
                case "Long/Short":
                    this.selectedTraderMode = "Long/Short"
                    break;
            default:
                this.selectedTraderMode = "Technical"
            }
        },
        onResize() {
             this.windowWidth = window.innerWidth;
        }
    },
}
</script>
