<template>
<div>
  <div class="block">
    <div class="border-b border-gray-700">
      <nav class="-mb-px flex justify-center space-x-8">
        <a v-for="tab in tabs" :href="tab.link" class="whitespace-no-wrap py-4 px-1 border-b-2 border-transparent font-medium text-sm leading-5  focus:outline-none" :class="tab.link == activePathname ? 'border-blue-500 text-blue-500 focus:text-blue-200 focus:border-blue-300' : 'text-gray-300 hover:text-gray-400 hover:border-gray-600 focus:text-gray-300 focus:border-gray-700'">
          {{ tab.name }}
        </a>
      </nav>
    </div>
  </div>
</div>
</template>

<script>
export default {
  data () {
    return {
      tabs: [
        {
          name: "Overview", link: "/admin"
        },
        {
          name: "Coins", link: "/admin/coins"
        },
        {
          name: "Users", link: "/admin/users"
        },
      ],
      activePathname: window.location.pathname
    }
  }
}
</script>