<template>
<modal-component @close="$emit('close')">
    <!--
      Modal panel, show/hide based on modal state.

      Entering: "ease-out duration-300"
        From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        To: "opacity-100 translate-y-0 sm:scale-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100 translate-y-0 sm:scale-100"
        To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
    -->
    <div class="bg-gray-900 rounded-lg px-4 pt-5 pb-4 overflow-hidden shadow-xl transform transition-all sm:max-w-sm sm:w-full sm:p-6" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
      <div>
        <div class="mt-3 sm:mt-5 devise">
          <h3 class="text-xl text-center leading-6 font-semibold text-white" id="modal-headline">
            Edit User #{{ user.id }}
          </h3>
          <div class="mt-4">
            <label class="label">Email</label>
            <input class="input-field mt-1" v-model="email">
          </div>
          <div class="mt-4">
            <label class="label">End Date <span class="float-right text-xs text-red-400">(Backup - doesn't affect Stripe)</span></label>
            <input type="date" class="input-field mt-1" v-model="endDate">
          </div>
          <div class="mt-4">
            <label class="label">Stripe ID</label>
            <input class="input-field mt-1" v-model="stripeId">
          </div>
          <div class="mt-4 flex items-center">
            <label class="label">Make User Admin?</label>
            <input type="checkbox" class="form-checkbox ml-4" v-model="isAdmin">
          </div>
        </div>
      </div>
      <div class="mt-5 sm:mt-6">
        <span class="flex w-full rounded-md shadow-sm">
          <button type="button" class="btn btn-blue w-full" @click="updateUser()">
            Save User
          </button>
        </span>
      </div>
    </div>
  </div>
</modal-component>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import ModalComponent from '../modal'
export default {
  components: {
    ModalComponent
  },
  data () {
    return {
      email: this.user.email,
      endDate: moment.utc(this.user.end_date).format("YYYY-MM-DD"),
      stripeId: this.user.stripe_id,
      isAdmin: this.user.is_admin
    }
  },
  props: ['user'],
  methods: {
    updateUser() {
      axios
      .patch("/admin/users", { editing: true, id: this.user.id, email: this.email, end_date: this.endDate, stripe_id: this.stripeId, is_admin: this.isAdmin })
      .then((response) => {
        this.$emit('close')
        this.$emit('success')
      })
      .catch((error) => alert(error.message))
    }
  }
}
</script>