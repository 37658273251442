<template>
  <div class="mx-auto max-w-2xl px-4 lg:px-0">
    <div class="">
      <h2 class="text-2xl font-bold tracking-tight">News Alerts</h2>
      <p class="text-base font-semibold text-gray-400">Get real-time alerts in the <a href="https://altcoinalert.slack.com/archives/C0162GLSXRR" target="_blank" class="text-blue-400">#significant-developments</a> Slack channel!</p>
    </div>
    <div class="space-y-4">
      <NewsItem v-for="news_item in newsItems" :key="news_item.id" :newsItem="news_item"> </NewsItem>
    </div>
    <div v-if="newsItems.length == 0" class="mt-10 text-gray-400">
      <span>Failed to load news — try refreshing the page.</span>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import NewsItem from "./news_item";
export default {
  components: {
    NewsItem,
  },
  data() {
    return {
      selectedCurrencies: ["USD"],
      newsItems: JSON.parse(JSON.stringify(this.news)),
      dataLastFetched: '',
      search: '',
    };
  },
  props: ['news'],
  mounted () {
    var self = this
    setInterval(function() {
      self.updateData()
    }, 30000)
  },
  methods: {
    updateData() {
      var self = this
      axios
        .get('/news')
        .then((response) => {
          self.newsItems = response.data
        })
        .catch((error) => {
          console.log(error)
        })
    },
  },
}
</script>