<template>
  <div>
    <label for="toggle" class="text-sm leading-5 font-medium focus:z-10">{{
      leftText
    }}</label>
    <div
      class="relative inline-block w-12 mr-2 align-middle select-none transition duration-200 ease-in"
    >
      <input
        type="checkbox"
        name="toggle"
        id="toggle"
        v-model="toggleValue"
        @change="$emit('change', toggleValue)"
        class="toggle-checkbox absolute block w-5 h-5 rounded-full bg-white border-2 appearance-none cursor-pointer outline-none focus-outline-none border-gray-700 checked:border-blue-900"
      />
      <label
        for="toggle"
        class="toggle-label block overflow-hidden h-5 rounded-full bg-gray-700 cursor-pointer flex items-center"
      >
        <span v-if="toggleValue" class="text-xxs font-bold ml-2 mt-1">ON</span>
        <span v-if="!toggleValue" class="text-xxs font-bold ml-5 mt-1">OFF</span>
      </label>
    </div>
    <label for="toggle" class="text-sm leading-5 font-medium focus:z-10">{{
      rightText
    }}</label>
  </div>
</template>
<script>
export default {
  props: {
    leftText: {
      type: String,
    },
    rightText: {
      type: String,
    },
    componentName: {
      type: String
    }
  },
  data: () => {
    return {
      toggleValue: false,
    };
  },
  beforeMount() {
    if(localStorage.easyMode) {
      this.toggleValue = localStorage.easyMode == "true" ? true : false;
      this.$emit('change', this.toggleValue)
    }
  }
};
</script>
<style>
.toggle-checkbox:checked {
  @apply right-0;
}
.toggle-checkbox:checked + .toggle-label {
  @apply bg-blue-900;
}
</style>
