export default {
  methods: {
    relativeActivity(value) {
      if (value < -0.5) {
        return "💩";
      } else if (value < -0.25) {
        return "👎";
      } else if (value < 0.25) {
        return "Normal";
      } else if (value < 1.0) {
        return "👍";
      } else {
        return "🔥";
      }
    },
    hype(value) {
      if (value < 1) {
        return { text: "Underhyped", pill: "pill-green" };
      } else if (value < 5) {
        return { text: "Normal", pill: "pill-yellow" };
      } else {
        return { text: "Overhyped", pill: "pill-red" };
      }
    },
    sentiment(value) {
      if (value < 20) {
        return { text: "Very Bearish", pill: "pill-red" };
      } else if (value < 40) {
        return { text: "Bearish", pill: "pill-red" };
      } else if (value < 60) {
        return { text: "Neutral", pill: "pill-yellow" };
      } else if (value < 80) {
        return { text: "Bullish", pill: "pill-green" };
      } else {
        return { text: "Very Bullish", pill: "pill-green" };
      }
    },
    elderImpulse(value) {
      if (value == 0) {
        return { text: "Neutral", pill: "pill-yellow" };
      }
      if (value == 1) {
        return { text: "Bullish", pill: "pill-green" };
      }
      if (value == -1) {
        return { text: "Bearish", pill: "pill-red" };
      }
      return { text: "", pill: "" };
    },
    aaScore(value) {
      if (value == null) {
        return { text: null, pill: "pill-yellow" };
      } else if (value < 20) {
        return { text: "Very Bearish", pill: "pill-red" };
      } else if (value < 40) {
        return { text: "Bearish", pill: "pill-red" };
      } else if (value < 60) {
        return { text: "Neutral", pill: "pill-yellow" };
      } else if (value < 80) {
        return { text: "Bullish", pill: "pill-green" };
      } else {
        return { text: "Very Bullish", pill: "pill-green" };
      }
    },
    sufficientTweets(coin) {
      return coin.tweet_volume > 12;
    },
    coinCount(coins, state, selectedMetric, includeVeryState) {
      if(selectedMetric == "AA Score™") {
          if(includeVeryState){
            return coins.filter(c => this.aaScore(c.score).text === state || this.aaScore(c.score).text === "Very " + state).length
          }
          return coins.filter(c => this.aaScore(c.score).text === state).length
      } else if(selectedMetric == "Elder Impulse (Daily)") {
          return coins.filter(c => this.elderImpulse(c.elder_impulse_signal).text === state).length
      }  else if(selectedMetric == "Elder Impulse (Hourly)") {
          return coins.filter(c => this.elderImpulse(c.elder_impulse_signal_hourly).text === state).length
      } else if(selectedMetric == "Short Term Sentiment"){
          if(includeVeryState){
            return coins.filter(c => this.sentiment(c.short_term_sentiment).text === state || this.sentiment(c.short_term_sentiment).text === "Very " + state).length
          }
          return coins.filter(c => this.sentiment(c.short_term_sentiment).text === state ).length
      } else if(selectedMetric == "Long Term Sentiment"){
          if(includeVeryState){
            return coins.filter(c => this.sentiment(c.long_term_sentiment).text === state || this.sentiment(c.long_term_sentiment).text === "Very " + state).length
          }
          return coins.filter(c => this.sentiment(c.long_term_sentiment).text === state).length
      }
      else if(selectedMetric == "Social Activity"){
        return coins.filter(c => this.relativeActivity(c.relative_tweet_volume - 1) == state).length
      }
      else if (selectedMetric = "Trading Activity"){
        return coins.filter(c => this.relativeActivity(c.relative_trading_volume - 1) == state).length
      }
    },
  },
};
