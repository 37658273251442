<template>
  <div class="mx-auto max-w-2xl">
    <div
      class="bg-gray-800 p-4"
      :class="latestNews ? 'border-l-4 border-blue-400 rounded-r-lg' : 'rounded-lg'"
    >
      <div class="flex flex-col">
        <div class="flex items-center justify-between mb-2">
          <span class="text-sm text-blue-400 font-semibold">
            {{ newsItem.news_feed.name }}
          </span>
          <span>
            <span v-if="latestNews" class="pill bg-blue-900 text-blue-200">NEW</span>
            <span class="text-sm text-gray-400">
              {{ newsItem.timestamp | timeWithinToday }}
            </span>
          </span>
        </div>
        <a
          :href="newsItem.link"
          target="_blank"
          class="hover:underline"
          :class = lineClamp
          >
          {{ newsItem.headline }}
        </a>
        <a
          :href="newsItem.link"
          target="_blank"
          class="mt-1 hover:underline text-xs text-gray-400"
          >
          {{ newsSource }}
        </a>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
export default {
  props: ["newsItem", "lineClamp"],
  computed: {
    latestNews(){
      return moment().diff(moment(this.newsItem.timestamp), "minutes") <= 10;
    },
    newsSource(){
      if (this.newsItem.found_in_page.includes(".")) {
        return this.newsItem.link
          .replace("https", "")
          .replace("http", "")
          .replace("://", "")
          .split("/")[0];
      } else {
        return this.newsItem.found_in_page;
      }
    }
  },
};
</script>
