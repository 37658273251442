export default {
  data () {
    return {
      sortColumn: 'market_cap',
      columnDirection: '',
      coinsToShow: 20
    }
  },
  methods: {
    sortByAttribute(array_of_objects, attribute, order = "asc", sentiment = false) {
      if (order == "") {
        attribute = 'market_cap'
        order = 'desc'
      }
      if (order == "asc") {
        return array_of_objects.slice().filter((obj) => obj[attribute] != null && !(sentiment && obj.tweet_volume < 12)).sort((obj1, obj2) => obj1[attribute] > obj2[attribute] ? 1 : -1);
      }
      else if (order == "desc") {
        return array_of_objects.slice().filter((obj) => obj[attribute] != null && !(sentiment && obj.tweet_volume < 12)).sort((obj1, obj2) => obj1[attribute] > obj2[attribute] ? -1 : 1);
      }
    },
    toggleColumnSort(attribute) {
      var columnDirections = ["", "desc", "asc"]
      if (this.sortColumn != attribute) {
        this.columnDirection = "desc"
      }
      else {
        // wrap around available column directions
        var currentDirectionIndex = columnDirections.findIndex((direction) => direction == this.columnDirection)
        this.columnDirection = columnDirections[(currentDirectionIndex + 1) % columnDirections.length]
      }
      this.sortColumn = attribute
    },
  },
}