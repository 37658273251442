// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

//require("@rails/ujs").start()
//require("@rails/activestorage").start()
require("channels")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
import '../css/application.scss';

import Vue from 'vue/dist/vue.esm'
import vSelect from 'vue-select'
Vue.component('v-select', vSelect)

import CoinsTable from "components/coins_table"
Vue.component("coins-table", CoinsTable)

import Coin from "components/coin"
Vue.component("coin", Coin)

import Navigation from "components/navigation"
Vue.component("top-navigation", Navigation)

import Overview from "components/admin/overview"
Vue.component("admin-overview", Overview)

import TabNav from "components/admin/tab_nav"
Vue.component("admin-tab-nav", TabNav)

import Users from "components/admin/users"
Vue.component("admin-users", Users)

import coins from "components/admin/coins"
Vue.component("admin-coins", coins)

import Ratings from "components/ratings"
Vue.component("ratings", Ratings)

import News from "components/news"
Vue.component("news", News)

import BillingPortalButton from "components/billing_portal_button"
Vue.component("billing-portal-button", BillingPortalButton)

import ExchangeLookup from "components/exchange_lookup"
Vue.component("exchange-lookup", ExchangeLookup)

import Dashboard from "components/dashboard";
Vue.component("dashboard", Dashboard);

require("./filters.js");

import axios from 'axios'

document.addEventListener('DOMContentLoaded', () => {
  axios.defaults.headers.common['X-CSRF-Token'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
  axios.defaults.headers.common['Accept'] = 'application/json'
  const app = new Vue({
    el: '#vue',
    methods: {
      textColor(value) {
        if (value > 0) {
          return 'text-green-400'
        }
        else if (value < 0) {
          return 'text-red-400'
        }
      }
    }
  })
})
