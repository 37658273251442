<template>
  <div>
    <h3 class="text-lg text-center my-8 font-bold leading-6 text-white">
      Summary Stats
    </h3>
    <div class="grid grid-cols-1 gap-5 sm:grid-cols-3">
      <div class="bg-gray-800 overflow-hidden shadow rounded-lg">
        <div class="px-4 py-5 sm:p-6">
          <dl>
            <dt class="text-sm leading-5 font-medium text-gray-300 truncate">
              Current Subscribers
            </dt>
            <dd class="mt-1 text-3xl leading-9 font-semibold text-white">
              {{ activeSubscribers.length }}
            </dd>
          </dl>
        </div>
      </div>
      <div class="bg-gray-800 overflow-hidden shadow rounded-lg">
        <div class="px-4 py-5 sm:p-6">
          <dl>
            <dt class="text-sm leading-5 font-medium text-gray-300 truncate">
              Subscribers Added in Past 7 Days
            </dt>
            <dd class="mt-1 text-3xl leading-9 font-semibold text-white">
              {{ addedSubscribers }}
            </dd>
          </dl>
        </div>
      </div>
      <div class="bg-gray-800 overflow-hidden shadow rounded-lg">
        <div class="px-4 py-5 sm:p-6">
          <dl>
            <dt class="text-sm leading-5 font-medium text-gray-300 truncate">
              Percent of Subscribers Active in Past 7 Days
            </dt>
            <dd class="mt-1 text-3xl leading-9 font-semibold text-white">
              {{ percentActiveSubscribers | decimals(0, 0) }}%
            </dd>
          </dl>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  mounted() {},
  data() {
    return {
      editableUsers: JSON.parse(JSON.stringify(this.users)),
    };
  },
  props: ["users"],
  methods: {
    daysAgo(datetime) {
      return moment().diff(moment(datetime), "days");
    },
  },
  computed: {
    activeSubscribers() {
      return this.editableUsers.filter((u) => u.is_subscribed);
    },
    percentActiveSubscribers() {
      return (
        (this.activeSubscribers.filter((user) => user.is_active).length /
          this.activeSubscribers.length) *
        100
      );
    },
    addedSubscribers() {
      var self = this;
      return this.users.filter(function (user) {
        return self.daysAgo(user.created_at) < 7 && !user.is_admin;
      }).length;
    },
  },
};
</script>
