<template>
  <div>
    <div class="mt-4 flex flex-col">
      <div class="flex items-center mb-2">
        <div class="z-0 inline-flex shadow-sm">
          <span
            v-for="(label, index) in userStatusLabels"
            :key="index"
            class="ml-1 cursor-pointer relative inline-flex items-center px-4 py-1 rounded-md border text-sm leading-5 font-medium focus:z-10 focus:outline-none transition ease-in-out duration-150"
            :class="{
              'border-blue-900 bg-blue-900 text-blue-200': label.isActive,
              'border-gray-700 bg-gray-900 text-gray-300': !label.isActive,
            }"
            @click="toggleUserStatus(label.status)"
          >
            {{ label.text }} ({{
              editableUsers.filter(label.filterFunction).length
            }})
          </span>
        </div>
        <input
          v-model="searchedUser"
          class="form-input bg-gray-800 text-sm border-gray-700 ml-4"
          placeholder="Search user email"
        />
        <form @submit.prevent="inviteUser()" class="flex items-center ml-auto">
          <input
            v-model="newUserEmail"
            class="form-input bg-gray-800 text-sm border-gray-700 ml-4"
            placeholder="Invite user email"
            required
          />
          <button class="ml-4 btn btn-blue w-32 text-center">+ Add User</button>
        </form>
        <button
          @click="downloadCSV"
          class="ml-4 btn btn-green w-32 text-center"
        >
          Download CSV
        </button>
      </div>
      <div class="py-2 sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
        <div class="align-middle inline-block min-w-full">
          <table class="min-w-full">
            <thead>
              <tr>
                <th
                  v-for="(attribute, idx) in attributes"
                  :key="idx"
                  :class="attribute.align"
                  class="sticky top-0"
                >
                  {{ attribute.name }}
                </th>
                <th class="sticky top-0"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="user in filteredUsers" :key="user.id">
                <td>
                  {{ user.id }}
                </td>
                <td>
                  {{ user.email }}
                </td>
                <td class="text-right">
                  {{ user.visits | decimals(0, 0) }}
                </td>
                <td class="text-right">
                  <span
                    class="text-xs text-gray-400"
                    v-if="user.last_visited_at"
                    >{{ user.last_visited_at | relativeTime }}</span
                  >
                  <span v-if="user.is_active" class="pill pill-green"
                    >Active</span
                  >
                  <span v-else class="pill pill-red">Inactive</span>
                </td>
                <td class="text-center">
                  <span :class="userStatus(user).pill">
                    {{ userStatus(user).text }}
                  </span>
                </td>
                <td class="text-right">
                  <span v-if="user.end_date">{{
                    user.end_date | numericalDate
                  }}</span>
                </td>
                <td class="text-left">
                  <a
                    :href="
                      'https://dashboard.stripe.com/customers/' + user.stripe_id
                    "
                    target="_blank"
                    class="border-b border-gray-500 hover:border-blue-500 hover:text-blue-500"
                    >{{ user.stripe_id }}</a
                  >
                </td>
                <td class="text-right">
                  <span
                    v-if="user.visits == 0"
                    @click="reinviteUser(user.id)"
                    class="cursor-pointer text-green-400"
                    >Reinvite</span
                  >
                  <span
                    class="ml-3 cursor-pointer text-gray-300"
                    @click="
                      showEditModal = true;
                      activeUser = user;
                    "
                    >Edit</span
                  >
                  <span
                    @click="deleteUser(user.id)"
                    class="ml-3 cursor-pointer text-red-400"
                    >Delete</span
                  >
                </td>
              </tr>
            </tbody>
          </table>
          <div class="flex justify-center my-5">
            <button
              v-if="
                limit < editableUsers.length && filteredUsers.length >= limit
              "
              class="ml-4 btn btn-blue w-56 text-center"
              @click="limit += 100"
            >
              Load More
            </button>
          </div>
        </div>
      </div>
    </div>
    <edit-modal
      v-if="showEditModal"
      @close="
        activeUser = '';
        showEditModal = false;
      "
      @success="reloadUsers()"
      :user="activeUser"
    ></edit-modal>
  </div>
</template>

<script>
import moment from "moment";
import axios from "axios";
import EditModal from "./edit_user_modal";
export default {
  components: {
    EditModal,
  },
  data() {
    return {
      attributes: [
        { name: "ID", align: "text-left" },
        { name: "Email", align: "text-left" },
        { name: "Number of Visits", align: "text-right" },
        { name: "Active?", align: "text-right" },
        { name: "Status", align: "text-center" },
        { name: "End Date", align: "text-right" },
        { name: "Stripe ID", align: "text-left" },
      ],
      showSubscribedUsers: true,
      showCancelledUsers: false,
      showActiveUsers: false,
      showInactiveUsers: false,
      searchedUser: "",
      newUserEmail: "",
      activeUser: "",
      showEditModal: false,
      editableUsers: JSON.parse(JSON.stringify(this.users)),
      today: moment.utc(),
      limit: 100,
      userStatusLabels: [
        {
          text: "Subscribed",
          status: "subscribed",
          isActive: this.showSubscribedUsers,
          filterFunction: (user) => user.is_subscribed,
        },
        {
          text: "Cancelled",
          status: "cancelled",
          isActive: this.showCancelledUsers,
          filterFunction: (user) => user.is_cancelled,
        },
        {
          text: "Active",
          status: "active",
          isActive: this.showActiveUsers,
          filterFunction: (user) => user.is_active,
        },
        {
          text: "Inactive",
          status: "inactive",
          isActive: this.showInactiveUsers,
          filterFunction: (user) => !user.is_active,
        },
      ],
    };
  },
  props: ["users"],
  mounted() {},
  methods: {
    inviteUser() {
      axios
        .post("/admin/users", { email: this.newUserEmail, is_admin: false })
        .then((response) => {
          alert("Invited " + this.newUserEmail);
          this.reloadUsers();
        })
        .catch((error) => alert(error.message));
    },
    toggleUserStatus(status) {
      this.userStatusLabels.forEach((label) => {
        if (label.status === status) {
          label.isActive = !label.isActive;
        } else {
          label.isActive = false;
        }
      });
      this.reloadUsers();
    },
    deleteUser(id) {
      if (confirm("Are you sure you want to delete user " + id + "?")) {
        axios
          .delete("/admin/users", { params: { id: id } })
          .then((response) => {
            alert("Deleted user!");
            this.reloadUsers();
          })
          .catch((error) => alert(error.message));
      }
    },
    reinviteUser(id) {
      if (confirm("Are you sure you want to reinvite user " + id + "?")) {
        axios.patch("/admin/users", { id: id, reinvite: true });
      }
    },
    userStatus(user) {
      if (user.is_cancelled) {
        return { text: "Cancelled", pill: "pill pill-red" };
      } else if (user.is_subscribed) {
        return { text: "Subscribed", pill: "pill pill-green" };
      }
      return { text: "", pill: "" };
    },
    reloadUsers() {
      axios
        .get("/admin/users")
        .then((response) => {
          this.editableUsers = response.data;
        })
        .catch((error) => alert(error.message));
    },
    downloadCSV() {
      const csvContent =
        "data:text/csv;charset=utf-8," +
        this.filteredUsers.map((user) => user.email).join("\n");
      const encodedUri = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "filtered_users.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  },
  computed: {
    filteredUsers() {
      var self = this;
      return this.editableUsers
        .filter(function (user) {
          var partOfSearch = false;
          if (self.searchedUser != "") {
            if (
              user.email.toLowerCase().includes(self.searchedUser.toLowerCase())
            ) {
              return true;
            } else {
              return false;
            }
          }
          self.userStatusLabels.forEach((label) => {
            if (label.isActive && label.filterFunction(user)) {
              partOfSearch = true;
            }
          });
          return partOfSearch;
        })
        .slice(0, this.limit);
    },
  },
};
</script>
