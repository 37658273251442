<template>
  <div class="p-0 z-50">
    <div class="bg-gray-900 rounded-lg py-3 px-2 sm:pl-4 sm:pr-10 overflow-hidden transform transition-all" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
      <div v-if="activityOverview" class="space-y-3" >
        <div class="flex justify-between">
          <span class="text-2xl mt-1">🔥</span>
          <span class="mt-1 tracking-tight">{{ coinCount(coins, "🔥", metric) }} coins</span>
        </div>
        <div class="flex justify-between">
          <span class="text-2xl mt-1">👍</span>
          <span class="mt-1 tracking-tight">{{ coinCount(coins, "👍", metric) }} coins</span>
        </div>
        <div class="flex justify-between">
          <span class="pill pill-yellow mt-1">Normal</span>
          <span class="mt-1 tracking-tight">{{ coinCount(coins, "Normal", metric) }} coins</span>
        </div>
        <div class="flex justify-between">
          <span class="text-2xl mt-1">👎</span>
          <span class="mt-1 tracking-tight">{{ coinCount(coins, "👎", metric) }} coins</span>
        </div>
        <div class="flex justify-between">
          <span class="text-2xl mt-1">💩</span>
          <span class="mt-1 tracking-tight">{{ coinCount(coins, "💩", metric) }} coins</span>
        </div>
      </div>
      <div v-else class="space-y-3">
        <div v-if="!filteredAttributes.includes(metric)" class="flex justify-between">
          <span class="pill pill-green pt-1">Very Bullish</span>
          <span class="mt-1 tracking-tight">{{ coinCount(coins, "Very Bullish", metric) }} coins</span>
        </div>
        <div class="flex justify-between">
          <span class="pill pill-green pt-1">Bullish</span>
          <span class="mt-1 tracking-tight">{{ coinCount(coins, "Bullish", metric) }} coins</span>
        </div>
        <div class="flex justify-between">
          <div class="pill pill-yellow pt-1">Neutral</div>
          <div class="mt-1 tracking-tight">{{ coinCount(coins, "Neutral", metric) }} coins</div>
        </div>
        <div class="flex justify-between">
          <div class="pill pill-red pt-1">Bearish</div>
          <div class="mt-1 tracking-tight">{{ coinCount(coins, "Bearish", metric) }} coins</div>
        </div>
        <div v-if="!filteredAttributes.includes(metric)" class="flex justify-between">
          <div class="pill pill-red pt-1">Very Bearish</div>
          <div class="mt-1 tracking-tight">{{ coinCount(coins, "Very Bearish", metric) }} coins</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import globalMixin from './mixins/global.js'
export default {
  mixins: [globalMixin],
  props: ['coins', 'metric', 'activityOverview'],
  data(){
    return {
      filteredAttributes: ['Elder Impulse (Hourly)', 'Elder Impulse (Daily)']
    }
  }
}
</script>
