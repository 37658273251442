<template>
<div>
  <analytics :users="users"></analytics>
  <h3 class="text-lg text-center mt-4 mb-8 font-bold leading-6 text-white mt-10">
    User Highlights
  </h3>
  <div class="flex space-x-10">
    <div class="w-1/2">
      <h3 class="text-gray-400 font-bold">Users Who Haven't Logged In</h3>
      <hr class="mt-2 mb-4 border-gray-700">
      <table class="w-full">
        <thead>
          <tr>
            <th class="text-left">Email</th>
            <th class="text-right">Invited</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="user in users.filter((u) => u.visits == 0)">
            <td class="text-left">{{ user.email }}</td>
            <td class="text-right">{{ user.created_at | relativeTime }}</td>
            <td class="text-right text-green-400">
              <span class="cursor-pointer" @click="reinviteUser(user.id)">Reinvite</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="w-1/2">
      <h3 class="text-gray-400 font-bold">10 Most Active Users</h3>
      <hr class="mt-2 mb-4 border-gray-700">
      <table class="w-full">
        <thead>
          <tr>
            <th class="text-left">Email</th>
            <th class="text-right">Visits</th>
            <th class="text-right">Last Visit</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="user in users.filter((u) => u.visits > 0).sort((a, b) => b.visits - a.visits).slice(0,10)">
            <td class="text-left">{{ user.email | truncate(30) }}</td>
            <td class="text-right">{{ user.visits | decimals(0,0) }}</td>
            <td class="text-right text-gray-400">{{ user.last_visited_at | relativeTime }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
</template>

<script>
import axios from 'axios'
import Analytics from './analytics.vue'
export default {
  data () {
    return {
    }
  },
  props: ['users'],
  components: {
    Analytics
  },
  methods: {
    reinviteUser(id) {
      if (confirm("Are you sure you want to reinvite user " + id + "?")) {
        axios
        .patch(('/admin/users'), { id: id, reinvite: true })
        .then((response) => alert("Reinvited user."))
        .catch((error) => alert(error.message))
      }
    }
  }
}
</script>