import Vue from 'vue/dist/vue.esm'
import moment from 'moment'

Vue.filter('percentage', function (val) {
  return val > 0 ? "+" + val + "%" : val + "%";
});

Vue.filter('decimals', function (value, minDecimals, maxDecimals) {
  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: minDecimals,
    maximumFractionDigits: maxDecimals
  }).format(value)
});

Vue.filter('usdCryptoPrice', function (value) {
  var decimals = value > 1 ? 2 : 6
  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals
  }).format(value)
});

Vue.filter('truncate', function (value, maxChars) {
  if (value.length > maxChars) {
    return value.slice(0, maxChars) + '...'
  }
  return value
});

// NumericalDate YYYY-MM-DD
Vue.filter('numericalDate', date => moment.utc(date).format('YYYY-MM-DD'))

Vue.filter('timeWithinToday', date => moment(date).format('YYYY-MM-DD h:mma'))

Vue.filter('relativeTime', date => moment.utc(date).fromNow())

Vue.filter('formatNumber', function (num, digits = 2) {
  var si = [
    { value: 1, symbol: "" },
    { value: 1E3, symbol: "k" },
    { value: 1E6, symbol: "M" },
    { value: 1E9, symbol: "B" },
    { value: 1E12, symbol: "T" },
    { value: 1E15, symbol: "P" },
    { value: 1E18, symbol: "E" }
  ];
  var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var i;
  for (i = si.length - 1; i > 0; i--) {
    if (num >= si[i].value) {
      break;
    }
  }
  return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
});