<template>
<nav class="bg-gray-800 flex flex-col w-full px-4 sm:px-6 lg:px-8">
  <div class="flex w-full items-center h-16">
    <a href="/" class="flex-shrink-0">
      <img src="../images/logo.png" class="block lg:hidden h-10 w-auto">
      <img src="../images/logo.png" class="hidden lg:block h-10 w-auto">
    </a>
    <div class="hidden sm:flex w-full space-x-4 justify-end sm:ml-6">
      <a v-for="link in links" v-if="link.admin ? admin : true" :href="link.link" :target="link.new_tab ? '_blank' : ''" class="px-3 py-2 rounded-md text-sm font-medium leading-5 text-white focus:outline-none focus:text-white focus:bg-gray-700 transition duration-150 ease-in-out" :class="currentPath == link.link ? 'bg-gray-900' : 'text-gray-300 hover:text-white hover:bg-gray-700'">{{ link.name }}</a>
    </div>
    <div class="-mr-2 ml-auto flex sm:hidden">
      <!-- Mobile menu button -->
      <button @click="expandMobileNav = !expandMobileNav" class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:bg-gray-700 focus:text-white transition duration-150 ease-in-out" aria-label="Main menu" aria-expanded="false">
        <!-- Icon when menu is closed. -->
        <!-- Menu open: "hidden", Menu closed: "block" -->
        <svg class="block h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16"/>
        </svg>
        <!-- Icon when menu is open. -->
        <!-- Menu open: "block", Menu closed: "hidden" -->
        <svg class="hidden h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"/>
        </svg>
      </button>
    </div>
  </div>

  <!--
    Mobile menu, toggle classes based on menu state.

    Menu open: "block", Menu closed: "hidden"
  -->
  <div class="sm:hidden" v-if="expandMobileNav">
    <div class="px-2 pt-2 pb-3 flex flex-col">
      <a v-for="link in links" :href="link.link" :target="link.new_tab ? '_blank' : ''" class="block px-3 py-2 rounded-md text-base font-medium focus:outline-none focus:text-white focus:bg-gray-700 transition duration-150 ease-in-out">{{ link.name }}</a>
    </div>
  </div>
</nav>  
</template>

<script>
export default {
  data () {
    return {
      expandMobileNav: false,
      links: [
         {
          name: "Dashboard", link: "/",
        },
        {
          name: "Altcoin Radar™", link: "/radar",
        },
        {
          name: "Ratings Feed", link: "/ratings",
        },
        {
          name: "News Alerts", link: "/news",
        },
        {
          name: "Exchange Lookup", link: "/exchange-lookup",
        },
        {
          name: "Admin", link: "/admin", admin: true,
        },
        {
          name: "Support Videos", link: "https://docs.altcoinalert.com", new_tab: true
        },
        {
          name: "Settings", link: "/users/edit",
        },
        {
          name: "Sign Out", link: "/users/sign_out",
        },
      ],
      currentPath: window.location.pathname
    }
  },
  props: ['admin'],
}
</script>