<template>
<div>
  <div class="mt-4 mb-10 flex flex-col">
    <div class="flex items-center mb-2">
      <span class="z-0 inline-flex shadow-sm">
          <span class="cursor-pointer relative inline-flex items-center px-4 py-1 rounded-md border text-sm leading-5 font-medium focus:z-10 focus:outline-none transition ease-in-out duration-150" :class="showVisibleCoins ? 'border-blue-900 bg-blue-900 text-blue-200' : 'border-gray-700 bg-gray-900 text-gray-300'" @click="showVisibleCoins = !showVisibleCoins">
              Visible ({{ this.editableCoins.filter((coin) => coin.visible).length }})
          </span>
          <span class="ml-1 cursor-pointer relative inline-flex items-center px-4 py-1 rounded-md border text-sm leading-5 font-medium focus:z-10 focus:outline-none transition ease-in-out duration-150" :class="showInvisibleCoins ? 'border-blue-900 bg-blue-900 text-blue-200' : 'border-gray-700 bg-gray-900 text-gray-300'" @click="showInvisibleCoins = !showInvisibleCoins">
              Invisible ({{ this.editableCoins.filter((coin) => !coin.visible).length }})
          </span>
      </span>
      <input v-model="searchedCoin" class="form-input bg-gray-800 text-sm border-gray-700 ml-4" placeholder="Search coin">
    </div>
    <div class="py-2 sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
      <div class="align-middle inline-block min-w-full">
        <table class="min-w-full">
          <thead>
            <tr>
              <th v-for="attribute in attributes" :class="attribute.align" class="sticky top-0">
                {{ attribute.name }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="coin in filteredCoins" :key="coin.id">
              <td>
                {{ coin.ticker }}
              </td>
              <td>
                {{ coin.name | truncate(20) }}
              </td>
              <td class="text-center">
                <input type="checkbox" v-model="coin.visible" @change="updateVisibility(coin)" class="form-checkbox">
              </td>
              <td class="text-center">
                <span :class="coin.disabled ? 'text-red-400' : 'text-green-400'">{{ coin.disabled ? 'Disabled' : 'Enabled' }}</span>
              </td>
              <td class="text-right">
                {{ coin.market_cap | formatNumber(2) }}
              </td>
              <td class="text-right">
                {{ coin.trading_volume | decimals(0,0) }}
              </td>
              <td class="text-right">
                <span title="Insufficient Tweet Volume" v-if="coin.tweet_volume < 12">
                  <svg class="h-5 w-5 text-red-400 inline mr-1" fill="currentColor" viewBox="0 0 20 20"><path d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd" fill-rule="evenodd"></path></svg>
                </span>
                {{ coin.tweet_volume | decimals(0,0) }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import axios from 'axios'
export default {
  data () {
    return {
      attributes: [
        { name: 'Ticker', align: 'text-left' },
        { name: 'Name', align: 'text-left' },
        { name: 'Visible', align: 'text-center' },
        { name: 'Status', align: 'text-center' },
        { name: 'Market Cap', align: 'text-right' },
        { name: '24hr Volume', align: 'text-right' },
        { name: 'Number of Tweets', align: 'text-right' },
      ],
      editableCoins: JSON.parse(JSON.stringify(this.coins)),
      showVisibleCoins: true,
      showInvisibleCoins: true,
      searchedCoin: ''
    }
  },
  props: ['coins'],
  methods: {
    updateVisibility(coin) {
      axios
      .patch(('/admin/coins'), { id: coin.id, visible: coin.visible })
      .then((response) => {
        // this.flashSuccess()
        this.getCoins()
      })
      .catch((error) => console.log(error))
    },
    getCoins() {
      axios
      .get('/admin/coins')
      .then((response) => this.editableCoins = response.data)
      .catch((error) => alert(error))
    }
  },
  computed: {
    filteredCoins() {
      var self = this
      return this.editableCoins.filter(function(coin) {
        var partOfSearch = false
        if (self.searchedCoin != '') {
          if (coin.ticker.toLowerCase() == self.searchedCoin.toLowerCase() || coin.name.toLowerCase().includes(self.searchedCoin.toLowerCase()) ) {
            return true
          }
          else {
            return false
          }
        }
        if (self.showVisibleCoins && coin.visible) {
          partOfSearch = true
        }
        else if (self.showInvisibleCoins && !coin.visible) {
          partOfSearch = true
        }
        return partOfSearch
      })
    }
  }
}
</script>