<template>
<div class="mx-auto max-w-2xl">
    <div class="flex flex-col md:flex-row items-center justify-between">
        <h2 class="text-2xl font-bold tracking-tight">Exchange Lookup</h2>
        <div class="relative w-1/2">
            <div class="flex w-full items-center rounded-lg bg-gray-800 text-gray-300 px-2 py-1">
              <svg class="w-4 h-4 flex-shrink-0" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
              </svg>
              <input type="text" data-id="search-exchange-input"
                    @input="findCoins" ref="search"
                    v-model="editableCoin"
                    @focus="searchFocused=true; searchedResults.length > 0 ? showDropdown=true : showDropdown=false"
                    class="w-full p-2 pb-1 text-sm bg-gray-800 focus:outline-none border-none font-bold focus:ring-0"
                    placeholder="Type to Search..."/>
            </div>
            <div v-if="showDropdown" @click="showDropdown = false" tabindex="-1" class="fixed inset-0 w-full h-full z-30"></div>
            <div v-if="showDropdown && searchedResults.length > 0" class="w-full absolute bg-gray-700 border-t border-gray-600 rounded-b shadow-lg -mt-1 z-50 overflow-y-auto" style="max-height:375px">
              <div tabindex="0" v-for="(coin,idx) in searchedResults" :key="coin.id" :data-dropdown-item="idx"
                  :class="focusedItem == idx ? 'bg-gray-500' : ''"
                  @focus="focusedItem=idx"
                  @click="getMostLiquidMarkets(coin)"
                  @keyup.enter="getMostLiquidMarkets(coin)"
                  class="inline-block min-w-full cursor-pointer hover:font-bold focus:outline-none focus:bg-gray-500 whitespace-no-wrap focus:font-bold hover:bg-gray-500 hover:bg-opacity-50 px-4 py-2 border-t border-gray-600">
                <span class="text-gray-100">{{ coin.name }}</span>
              </div>
            </div>
        </div>
    </div>
    <div class="mt-4 bg-gray-800 p-4 rounded-lg">
      <table class="w-full">
        <thead>
          <tr>
            <th class="bg-gray-800 text-left">Exchange</th>
            <th class="bg-gray-800 text-left">Pair</th>
            <th class="bg-gray-800 text-right">Volume (USD)</th>
            <th class="bg-gray-800 text-right">Spread</th>
            <th class="bg-gray-800 text-right"></th>
          </tr>
        </thead>
        <tbody>
          <template v-if="!loading">
            <tr v-for="(market,idx) in mostLiquidMarkets" :key="idx">
              <td class="bg-gray-800 text-gray-300 text-left">
                <img :src="market.market.logo" class="w-4 h-4 inline">
                <span>{{ market.market.name }}</span>
              </td>
              <td class="bg-gray-800 text-left text-gray-300">{{ market.base | truncate(6) }}/{{ market.target | truncate(6) }}</td>
              <td class="bg-gray-800 text-right text-gray-300">${{ market.converted_volume.usd | formatNumber }}</td>
              <td class="bg-gray-800 text-right text-gray-300">{{ market.bid_ask_spread_percentage | decimals(2,2) }}%</td>
              <td class="bg-gray-800 text-right">
                <a :href="market.trade_url" target="_blank" class="text-xs uppercase tracking-wider font-semibold text-blue-400">Trade</a>
              </td>
            </tr>
          </template>
          <template v-else>
            <tr>
              <td colspan="5" class="py-10 bg-gray-800">
                <img class="mx-auto" src="../images/loader.svg"/>
              </td>  
            </tr>  
          </template>
        </tbody>
      </table>
    </div>
</div>
</template>

<script>
import axios from 'axios'
import _ from 'lodash'

export default {
    data () {
        return {
            search: '',
            mostLiquidMarkets: [],
            showDropdown: false,
            searchedResults: [],
            searchFocused: false,
            editableCoin: '',
            focusedItem: 0,
            loading: false
        }
    },
    props: {
      coins: Array,
    },
    mounted() {
      window.addEventListener('keydown', this.keyDown)
    },
    beforeDestroy() {
      window.removeEventListener('keydown', this.keyDown)
    },
    watch: {
    showDropdown() {
      if(this.editableCoin == "" && this.showDropdown==false){
        this.searchedResults = []
        this.mostLiquidMarkets = []
      }
    }
  },
    methods: {
      keyDown(e){
        console.log('s')
        const self = this
      if(e.key === 'Escape'){
        self.blurInput()
      }
      if(e.key === 'Enter'){
        var firstResult = this.searchedResults ? this.searchedResults[this.focusedItem] : null
        if(firstResult){
          this.getMostLiquidMarkets(firstResult)
        }
      }
      if(self.searchFocused && e.key === 'ArrowUp'){
        e.preventDefault()
        this.focusedItem--;
        if(this.focusedItem >= 0){
          document.querySelector('[data-dropdown-item="'+this.focusedItem+'"]').focus()
        }else{
          this.focusedItem = 0
          document.querySelector('[data-id="search-exchange-input"]').focus()
        }
      }
      if(self.searchFocused && e.key === 'ArrowDown'){
        e.preventDefault()
        this.focusedItem++;
        if(this.focusedItem < this.searchedResults.length){
          document.querySelector('[data-dropdown-item="'+this.focusedItem+'"]').focus()
        }else{
          this.focusedItem = this.searchedResults.length - 1
        }
      }
      },
      getMostLiquidMarkets(coin) {
        this.loading = true
        this.search = coin.coin_id.toLowerCase().replace(" ", "")
        this.editableCoin = coin.name
        this.showDropdown = false
        this.searchedResults = []
        this.searchedResults[0] = coin
        axios
        .get(`https://api.coingecko.com/api/v3/coins/${this.search}/tickers`, { params: { include_exchange_logo: "true" }})
        .then(response => {
          this.mostLiquidMarkets = response.data.tickers.filter(pair => pair.trust_score == 'green').sort((a,b) => b.converted_volume.usd > a.converted_volume.usd ? 1 : -1)
          this.loading = false
        })
        .catch(error => console.log(error))

      },
      findCoins: _.debounce(function (e) {
        if(!this.searchFocused){
          this.editableCoin = ''
          return
        }
        this.showDropdown = true
        this.focusedItem = 0
        const key = e.target.value.toLowerCase()
        if (key === '') {
          this.searchedResults = []
        } else {
          this.searchedResults = this.coins.filter(x => {
            return x.name.toLowerCase().includes(key) || x.coin_id.toLowerCase().includes(key) || x.symbol.toLowerCase().includes(key)
          })
        }
      }, 500),
    },
    blurInput() {
      if(this.searchFocused){
        this.editableCoin = ''
        this.searchFocused = false
        this.searchedResults = []
        document.querySelector('[data-id="search-exchange-input"]').blur();
      }
    },
}
</script>