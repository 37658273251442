<template>
<div class="flex overflow-x-auto mt-3 text-sm space-x-10">
    <div class="bg-gray-800 flex-shrink-0 sm:flex-shrink rounded pt-4 pb-2 w-5/6 sm:w-1/3 sm:max-w-full">
        <div class="px-4 pb-1 text-gray-400">
            <span class="">Top 5 AA Score™</span>
        </div>
        <div v-for="coin in top5PerformersAAScore">
            <a class="flex justify-between py-1 px-4 hover:bg-gray-700" :href="`/coins/${coin.ticker}`">
                <span>{{ coin.name }} ({{ coin.ticker }})</span>
                <div class="">
                    <span class="pill" :class="aaScore(coin.score).pill">{{ aaScore(coin.score).text }}</span>
                    {{ coin.score | decimals(1,1) }}
                </div>
            </a>
        </div>
    </div>
    <div class="bg-gray-800 flex-shrink-0 sm:flex-shrink rounded pt-4 pb-2 w-5/6 sm:w-1/3 sm:max-w-full">
        <div class="flex justify-between px-4 pb-1 text-gray-400">
            <span class="">Top 5 Day's Winners</span>
            <span class="ml-auto">(vs. USD | BTC)</span>
        </div>
        <div v-for="coin in top5PerformersUSD">
            <a class="flex justify-between py-1 px-4 hover:bg-gray-700" :href="`/coins/${coin.ticker}`">
                <span>{{ coin.name }} ({{ coin.ticker }})</span>
                <div class="">
                    <span :class="$root.textColor(coin.price_change_usd_24hr)">{{ coin.price_change_usd_24hr  | decimals(2,2) | percentage }}</span> <span class="text-gray-500">|</span> 
                    <span :class="$root.textColor(coin.price_change_btc_24hr)">{{ coin.price_change_btc_24hr  | decimals(2,2) | percentage }}</span> 
                </div>
            </a>
        </div>
    </div>
    <div class="bg-gray-800 flex-shrink-0 sm:flex-shrink rounded pt-4 pb-2 w-5/6 sm:w-1/3 sm:max-w-full">
        <div class="flex justify-between px-4 pb-1 text-gray-400">
            <span class="">Top 5 Day's Losers</span>
            <span class="ml-auto">(vs. USD | BTC)</span>
        </div>
        <div v-for="coin in bottom5PerformersUSD">
            <a class="flex justify-between py-1 px-4 hover:bg-gray-700" :href="`/coins/${coin.ticker}`">
                <span>{{ coin.name }} ({{ coin.ticker }})</span>
                <div class="">
                    <span :class="$root.textColor(coin.price_change_usd_24hr)">{{ coin.price_change_usd_24hr  | decimals(2,2) | percentage }}</span> <span class="text-gray-500">|</span> 
                    <span :class="$root.textColor(coin.price_change_btc_24hr)">{{ coin.price_change_btc_24hr  | decimals(2,2) | percentage }}</span> 
                </div>
            </a>
        </div>
    </div>
</div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
export default {
    props: ['coins'],
    computed: {
        top5PerformersUSD() {
            return this.coins.slice().sort((a,b) => b.price_change_usd_24hr - a.price_change_usd_24hr).slice(0,5)
        },
        bottom5PerformersUSD() {
            return this.coins.slice().sort((a,b) => a.price_change_usd_24hr - b.price_change_usd_24hr).slice(0,5)
        },
        top5PerformersAAScore() {
            return this.coins.slice().filter((coin) => coin.score != null).sort((a,b) => b.score - a.score).slice(0,5)
        },
    },
    methods: {
        aaScore(value) {
            if (value < 20) {
                return { text: "Very Bearish", pill: "pill-red" }
            }
            else if (value < 40) {
                return { text: "Bearish", pill: "pill-red" }
            }
            else if (value < 60) {
                return { text: "Neutral", pill: "pill-yellow" }
            }
            else if (value < 80) {
                return { text: "Bullish", pill: "pill-green" }
            }
            else {
                return { text: "Very Bullish", pill: "pill-green" }
            }
        },
    }
}
</script>