<template>
<div class="mt-8 container mx-auto flex flex-col px-4 lg:px-0">
    <div class="md:flex items-center leading-none justify-between">
        <div class="flex flex-col space-y-2">
            <div class="flex items-center space-x-2">
                <span class="text-lg lg:text-xl font-extrabold">{{ coin.name }}</span>
                <span class="font-semibold text-gray-400 text-lg mt-0.5">{{ coin.ticker }}</span>
            </div>
            <div class='flex space-x-4'>
                <div class="flex items-center space-x-2">
                    <span class="text-lg lg:text-xl font-bold text-right">${{ coinInfo.price_usd | usdCryptoPrice }}</span>
                    <span class="text-base lg:text-lg font-semibold text-right" :class="$root.textColor(coinInfo.price_change_usd_24hr)">{{ coinInfo.price_change_usd_24hr | decimals(2,2) | percentage }}</span>
                </div>
                <div class="flex items-center space-x-4">
                    <span class="text-gray-300 hidden sm:block">AA Score</span>
                    <div>
                    <p v-if="coinInfo.score" class="px-4 py-1 flex items-center justify-center rounded-full text-xl font-bold" :class="aaScore(coinInfo.score).pill"><span>{{ coinInfo.score | decimals(1,1) }}</span></p>
                    <span v-else class="text-xs text-gray-400">not scored</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="mt-2 sm:mt-0 flex space-x-2 shadow-sm">
            <span v-for="(freq,idx) in frequencies" :key="idx"
                class="cursor-pointer relative inline-flex items-center px-4 py-1 rounded-md border text-sm leading-5 font-medium focus:z-10 focus:outline-none transition ease-in-out duration-150"
                :class="selectedFrequency == freq ? 'border-blue-900 bg-blue-900 text-blue-200' : 'border-gray-700 bg-gray-900 text-white'"
                @click="selectedFrequency = freq">
                {{ freq }}
            </span>
        </div>
    </div>
    <div class="mt-2 w-full bg-gray-800 p-2 pt-4 shadow rounded" style="height: 444px">
        <div v-if="loading" class="mt-20 loader loader--style3" title="2">
            <svg version="1.1" class="mx-auto" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
            <path fill="#4493F7" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z">
                <animateTransform attributeType="xml"
                attributeName="transform"
                type="rotate"
                from="0 25 25"
                to="360 25 25"
                dur="0.6s"
                repeatCount="indefinite"/>
                </path>
            </svg>
        </div>
        <div :class="{'hidden': loading}" id="chart" class="w-full h-full"></div>
    </div>
    <div class="flex items-center space-x-5 justify-center mt-3 text-sm">
        <div class="flex items-center space-x-2">
            <div class="w-5 h-1 bg-white"></div>
            <span>AA Score</span>
        </div>
        <div class="flex items-center space-x-2">
            <div class="w-5 h-1 bg-blue-400"></div>
            <span>Price</span>
        </div>
    </div>
    <p class="text-xs mx-auto mt-2 text-gray-300">Note: there is no significance in the interaction of the two lines - that is coincidental based solely on the scale of the axes. Simply, there is no TA to be done here.</p>
    <div class="flex flex-col md:flex-row w-full space-y-8 md:space-y-0 md:space-x-8 my-8 justify-center">
        <div class="flex flex-col md:w-1/5 bg-gray-800 p-4 items-center">
            <span class="text-xs font-semibold text-gray-300">Market Cap</span>
            <span class="text-2xl font-bold">${{ coin.market_cap | formatNumber(2) }}</span>
            <span :class="$root.textColor(coinInfo.price_change_usd_24hr)">{{ coinInfo.price_change_usd_24hr | decimals(2,2) | percentage }}</span>
        </div>
        <div class="flex flex-col md:w-1/5 bg-gray-800 p-4 items-center">
            <span class="text-xs font-semibold text-gray-300">Trading Volume</span>
            <span class="text-2xl font-bold">${{ coin.trading_volume | formatNumber(2) }}</span>
            <span :class="$root.textColor(coin.relative_trading_volume)">{{ coin.relative_trading_volume | decimals(2,2) | percentage }}</span>
        </div>
        <div class="flex flex-col md:w-1/5 bg-gray-800 p-4 items-center">
            <span class="text-xs font-semibold text-gray-300">Tweet Volume</span>
            <span class="text-2xl font-bold">{{ coin.tweet_volume | decimals(0,0) }}</span>
            <span :class="$root.textColor(coin.relative_tweet_volume)">{{ coin.relative_tweet_volume | decimals(2,2) | percentage }}</span>
        </div>
        <div class="flex flex-col md:w-1/5 bg-gray-800 p-4 items-center">
            <span class="text-xs font-semibold text-gray-300">Short Term Sentiment</span>
            <span class="mt-1 px-3 py-0.5 rounded-full" :class="sentiment(coin.short_term_sentiment).pill">{{ sentiment(coin.short_term_sentiment).text }}</span>
        </div>
        <div class="flex flex-col md:w-1/5 bg-gray-800 p-4 items-center">
            <span class="text-xs font-semibold text-gray-300">Long Term Sentiment</span>
            <span class="mt-1 px-3 py-0.5 rounded-full" :class="sentiment(coin.long_term_sentiment).pill">{{ sentiment(coin.long_term_sentiment).text }}</span>
        </div>
    </div>
    <div class="flex flex-col md:flex-row w-full space-y-8 md:space-y-0 md:space-x-8 mb-8">
        <div class="flex flex-col md:w-1/5 bg-gray-800 p-4 items-center">
            <span class="text-xs font-semibold text-gray-300">Elder Impulse (Daily)</span>
            <span class="mt-1 px-3 py-0.5 rounded-full" :class="elderImpulse(coin.elder_impulse_signal).pill">{{ elderImpulse(coin.elder_impulse_signal).text }}</span>
        </div>
        <div class="flex flex-col md:w-1/5 bg-gray-800 p-4 items-center">
            <span class="text-xs font-semibold text-gray-300">Elder Impulse (Hourly)</span>
            <span class="mt-1 px-3 py-0.5 rounded-full" :class="elderImpulse(coin.elder_impulse_signal_hourly).pill">{{ elderImpulse(coin.elder_impulse_signal_hourly).text }}</span>
        </div>
        <div class="flex flex-col md:w-1/5 bg-gray-800 p-4 items-center">
            <span class="text-xs font-semibold text-gray-300">1hr Proj. Range</span>
            <div v-if="coin.price_prediction_percent && coin.price_prediction_percent < 40 && coin.price_prediction_percent_lower > -50 && coin.price_prediction_percent_upper < 100" class="text-right flex items-center space-x-3 mt-2">
                <p class="text-xs">
                    <span :class="$root.textColor(coin.price_prediction_percent_upper)">
                        {{ coin.price_prediction_percent_upper | decimals(2,2) | percentage}}
                    </span>
                </p>
                <p :class="$root.textColor(coin.price_prediction_percent)">
                    {{ coin.price_prediction_percent | decimals(2,2) | percentage }}
                </p>
                <p class="text-xs">
                    <span :class="$root.textColor(coin.price_prediction_percent_lower)">
                        {{ coin.price_prediction_percent_lower | decimals(2,2) | percentage}}
                    </span>
                </p>
            </div>
            <span v-else class="text-xs text-gray-400">n/a</span>
        </div>
        <div class="flex flex-col md:w-1/5"></div>
        <div class="flex flex-col md:w-1/5"></div>
    </div>
</div>
</template>

<script>
import axios from 'axios'
import globalMixin from './mixins/global.js'

export default {
    mixins: [globalMixin],
    data () {
        return {
            frequencies: ['3D','1W','1M','1Y'],
            selectedFrequency: '3D',
            chartData: [],
            priceSeries: undefined,
            sentimentSeries: undefined,
            chartObj: undefined,
            loading: false,
            timerId: undefined,
            coinInfo: this.coin
        }
    },
    props: ['coin'],
    mounted () {
        const self = this
        self.updateTitle()
        self.loadChart()
    },
    watch: {
        selectedFrequency(){
            this.loadChart()
        }
    },
    methods: {
        barWidth(coin) {
            var fullRange = coin.price_prediction_percent_upper - coin.price_prediction_percent_lower
            return coin.price_prediction_percent / fullRange * 100 + 50
        },
        getMarginStyle(coin) {
            var value = this.barWidth(coin);
            return {'margin-left': value - 4 + '%'};
        },
        updateTitle() {
            document.title = `${this.coin.name} (${this.coin.ticker}) - Altcoin Alert`
        },
        loadChart(){
            const self = this
            clearInterval(this.timerId);
            self.loading = true
            if(self.chartObj){
                self.removeSeries()
            }
            axios.get(`/coins/${self.coin.ticker}/${self.selectedFrequency}/chart`)
            .then(response => {
                self.loading = false;
                self.chartData = response.data
                delete self.chartData.coin
                self.$nextTick(() => {
                    if(!self.chartObj){
                        self.plotChart()
                        self.addChartSeries()
                    }
                    self.updateSeries()
                    self.updateChart()
                    self.timerId = setInterval(self.updateChart, 30 * 1000);
                })
            })
        },
        updateChart(){
          const self = this
          axios.get(`/coins/${self.coin.ticker}/recent`)
                .then(response => {
                    self.chartData.prices.push(response.data.price)
                    self.chartData.ratings.push(response.data.rating)
                    self.coinInfo = response.data.coin
                    self.updateSeries()
                })
        },
        plotChart(){
            this.chartObj = LightweightCharts.createChart(document.getElementById("chart"), {
                width: document.getElementById("chart").width,
                height: document.getElementById("chart").height,
                handleScale: {
                    mouseWheel: false,
                    pinch: false
                },
                handleScroll: {
                    mouseWheel: false,
                    pressedMouseMove: false,
                    horzTouchDrag: false,
                    vertTouchDrag: false
                },
                rightPriceScale: {
                    visible: true,
                    border: {
                        color: '#374151'
                    }
                },
                leftPriceScale: {
                    visible: true,
                    border: {
                        color: '#374151'
                    }
                },
                layout: {
                    background: {
                        color: '#252f3f'
                    },
                    textColor: '#9fa6b2',
                },
                grid: {
                    horzLines: {
                        color: '#374151',
                    },
                    vertLines: {
                        color: '#374151',
                    },
                },
                timeScale: {
                    timeVisible: true,
                    secondsVisible: false,
                    border: {
                        color: '#374151'
                    },
                    lockVisibleTimeRangeOnResize: true
                }
            });
        },
        removeSeries(){
            this.priceSeries.setData([])
            this.sentimentSeries.setData([])
        },
        addChartSeries(){
            const self = this;
            self.priceSeries = self.chartObj.addLineSeries({
                lineWidth: 2,
                priceScaleId: "right",
                color: '#60A5FA'
            });
            self.sentimentSeries = self.chartObj.addLineSeries({
                priceScaleId: "left",
                color: '#ffffff',
                lineWidth: 2
            });
        },
        updateSeries() {
            const self = this
            self.priceSeries.setData(
                self.chartData.prices.map(price => {
                    return { time: price[0], value: price[1] }
                })
            )
            self.sentimentSeries.setData(
                self.chartData.ratings.map(rating => {
                    return { time: rating[0], value: rating[1] }
                })
            )
            self.chartObj.timeScale().fitContent();
        }
    }
}
</script>
