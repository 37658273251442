<template>
<button @click="visitPortal()" class="btn btn-blue w-64">
    Go to billing portal <svg viewBox="0 0 20 20" fill="currentColor" class="arrow-narrow-right w-5 h-5 ml-1 inline"><path fill-rule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
</button>
</template>

<script>
import axios from 'axios'
export default {
    methods: { 
        visitPortal() {
            axios
            .get('/billing-portal-url')
            .then((response) => {
                window.location.href = response.data.url
            })
            .catch((error) => {
                alert("Something went wrong trying to redirect you to the billing portal. Please email support@altcoinalert.com for help.")
            })
        },
    }
}
</script>