<template>
  <div class="p-2 space-y-4">
    <div class="flex w-full md:w-3/4 h-10 pr-4 items-end justify-between text-white">
      <EasyModeToggle left-text="Easy Mode" componentName="Dashboard" @change="setEasyMode($event)" />
      <div class="hidden md:flex flex-col items-center" v-if="!easyMode">
        <span class="ml-auto z-0 inline-flex shadow-sm">
          <div v-for="(sentimentOption,idx) in sentimentOptions" :key="idx">
            <span
              class="ml-1 cursor-pointer relative inline-flex items-center px-4 py-1 rounded-md border text-sm leading-5 font-medium focus:z-10 focus:outline-none transition ease-in-out duration-150"
              :class="
              selectedSentiment === sentimentOption
                ? 'border-blue-900 bg-blue-900 text-blue-200'
                : 'border-gray-700 bg-gray-900 text-white'
            "
              @click="selectedSentiment = sentimentOption"
            >
              {{ sentimentOption }}
            </span>
          </div>
        </span>
      </div>
    </div>
    <div class="md:flex space-y-4 md:space-y-0 md:space-x-4">
      <div class="w-full md:w-3/4">
        <div v-if="!easyMode" class="-my-2">
          <div class="flex flex-wrap -mx-2 overflow-hidden">
            <div v-for="config in easyModeOffConfig" :key="config.id" class="my-2 px-2 w-full overflow-hidden md:w-1/2">
              <div class="bg-gray-800 rounded-md py-4">
                <div class="flex justify-between text-gray-400 px-4">
                  <span>{{ config.title }}</span>
                  <div @click="toggleSortOrder(config.id, config.sortOrder)">
                    <svg v-if="config.sortOrder == 'ASC'" xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 text-gray-300 ml-1 cursor-pointer hover:text-gray-100" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7l4-4m0 0l4 4m-4-4v18" />
                    </svg>
                    <svg v-if="config.sortOrder == 'DESC' || config.sortOrder == ''" xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 text-gray-300 ml-1 cursor-pointer hover:text-gray-100" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 17l-4 4m0 0l-4-4m4 4V3" />
                    </svg>
                  </div>
                  <template v-if="config.id == 'topGainers'">
                    <span class="ml-auto">Price</span>
                  </template>
                  <template v-if="config.id == 'topElders'">
                    <span class="ml-auto" v-if="selectedSentiment === 'Long-Term'">Daily</span>
                    <span class="ml-auto" v-if="selectedSentiment === 'Short-Term'">Hourly</span>
                  </template>
                  <template v-if="config.id == 'topAAScores'">
                    <span class="ml-auto">Score</span>
                  </template>
                  <template v-if="config.id == 'topSentiments'">
                    <span class="ml-auto" v-if="selectedSentiment === 'Long-Term'">Daily</span>
                    <span class="ml-auto" v-if="selectedSentiment === 'Short-Term'">Hourly</span>
                  </template>
                </div>
                <div class="h-72 overflow-y-auto">
                <div v-for="coin in getComputedData(config.id)" :key="coin.id" class="flex justify-between py-2 px-4 hover:bg-gray-700">
                  <a :href="`/coins/${coin.ticker}`">
                    <div>
                      <div class="text-sm leading-5">
                        {{ coin.name | truncate(12) }}
                      </div>
                      <div class="text-sm leading-5 text-gray-400">
                        {{ coin.ticker }}
                      </div>
                    </div>
                  </a>
                  <div>
                    <template v-if="config.id == 'topGainers'">
                      <div class="text-sm leading-5 font-medium text-right">
                        ${{ coin.price_usd | usdCryptoPrice }}
                      </div>
                      <div class="text-sm leading-5 text-right" :class="$root.textColor(coin.price_change_usd_24hr)">
                        {{ coin.price_change_usd_24hr | decimals(2, 2) | percentage }}
                      </div>
                    </template>
                    <template v-if="config.id == 'topElders'">
                      <span
                        class="pill"
                        :class="elderImpulse(coin.elder_impulse_signal).pill"
                        v-if="selectedSentiment === 'Long-Term'"
                      > 
                        {{ elderImpulse(coin.elder_impulse_signal).text }}
                      </span>
                      <span
                        class="pill"
                        :class="elderImpulse(coin.elder_impulse_signal_hourly).pill"
                        v-if="selectedSentiment === 'Short-Term'"
                      >
                        {{ elderImpulse(coin.elder_impulse_signal_hourly).text }}
                      </span>
                    </template>
                    <template v-if="config.id == 'topAAScores'">
                      <span
                        v-if="coin.score"
                        class="pill"
                        :class="aaScore(coin.score).pill"
                      >
                        {{ aaScore(coin.score).text }}
                      </span>
                      <span v-if="coin.score" class="">
                        {{ coin.score | decimals(1, 1) }}
                      </span>
                      <span v-else class="text-xs text-gray-500">not scored</span>
                    </template>
                    <template v-if="config.id == 'topSentiments'">
                      <div v-if="selectedSentiment === 'Short-Term'">
                        <span
                          v-if="sufficientTweets(coin)"
                          class="pill"
                          :class="sentiment(coin.short_term_sentiment).pill"
                        >
                          {{ sentiment(coin.short_term_sentiment).text }}
                        </span>
                        <span v-else class="text-xs text-gray-500 leading-tight">not enough<br />tweets</span>
                      </div>
                      <div v-if="selectedSentiment === 'Long-Term'">
                        <span
                          v-if="sufficientTweets(coin)"
                          class="pill"
                          :class="sentiment(coin.long_term_sentiment).pill"
                        >
                          {{ sentiment(coin.long_term_sentiment).text }}
                        </span>
                        <span v-else class="text-xs text-gray-500 leading-tight">not enough<br />tweets</span>
                      </div>
                    </template>
                  </div>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="easyMode">
          <div class="overflow-x-auto sm:overflow-visible">
            <div class="min-w-full rounded-md bg-gray-800 p-4">
              <table class="min-w-full table-auto">
                <tbody>
                  <td
                    v-for="(attribute, id) in topOpportunityColumns"
                    :class="[attribute.align || 'text-right', id === 'top_opportunity' ? 'sticky left-0 sm:static bg-gray-800': '']"
                    :key="id"
                    class="cursor-pointer select-none pb-1 text-gray-400 text-base border-none"
                  >
                    <span :class="[attribute.margin]">
                      {{ attribute.name }}
                    </span>
                  </td>
                  <tr v-for="coin in topOpportunities" :key="coin.id">
                    <td class="sticky left-0 sm:static bg-gray-800 sm:bg-transparent border-none">
                      <div class="flex items-center">
                        <a :href="`/coins/${coin.ticker}`">
                          <div>
                            <div class="text-sm leading-5 font-medium">
                              {{ coin.name | truncate(12) }}
                            </div>
                            <div class="text-sm leading-5 text-gray-400">
                              {{ coin.ticker }}
                            </div>
                          </div>
                        </a>
                      </div>
                    </td>
                    <td class="border-none">
                      <div>
                        <div class="text-sm leading-5 font-medium text-right">
                          ${{ coin.price_usd | usdCryptoPrice }}
                        </div>
                        <div class="text-sm leading-5 text-right" :class="$root.textColor(coin.price_change_usd_24hr)">
                          {{ coin.price_change_usd_24hr | decimals(2, 2) | percentage }}
                        </div>
                      </div>
                    </td>
                    <td class="text-right border-none">
                      <span
                        v-if="coin.score"
                        class="pill"
                        :class="aaScore(coin.score).pill"
                        >{{ aaScore(coin.score).text }}</span
                      >
                      <span v-if="coin.score" class="">
                        {{ coin.score | decimals(1,1) }}
                      </span>
                      <span v-else class="text-xs text-gray-500">not scored</span>
                    </td>
                    <td class="text-right border-none">
                      <span
                        class="pill"
                        :class="elderImpulse(coin.elder_impulse_signal_hourly).pill"
                        >
                        {{ elderImpulse(coin.elder_impulse_signal_hourly).text }}
                      </span>
                    </td>
                    <td class="text-right border-none">
                      <span
                        class="pill"
                        :class="elderImpulse(coin.elder_impulse_signal).pill"
                        >
                        {{ elderImpulse(coin.elder_impulse_signal).text }}
                      </span>
                    </td>
                    <td class="text-right border-none">
                      <span
                        v-if="sufficientTweets(coin)"
                        class="pill"
                        :class="sentiment(coin.short_term_sentiment).pill"
                        >{{ sentiment(coin.short_term_sentiment).text }}</span
                      >
                      <span v-else class="text-xs text-gray-500 leading-tight">not enough<br />tweets</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="w-full md:w-1/4">
        <div class="space-y-4">
          <NewsItem v-for="news_item in newsItems" :key="news_item.id" :newsItem="news_item" :lineClamp="'line-clamp-3'"></NewsItem>
        </div>
        <div v-if="newsItems.length == 0" class="mt-10 text-gray-400">
          <span>Failed to load news — try refreshing the page.</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import EasyModeToggle from "./easy_mode_toggle.vue";
import NewsItem from "./news_item.vue";
import globalMixin from "./mixins/global.js";
export default {
  name: "Dashboard",
  mixins: [globalMixin],
  mounted() {
    const self = this;
    self.coins = [...self.allCoins]
    self.fetchNewsItems();
    self.newsInterval = setInterval(() => {
      self.fetchNewsItems();
    }, 30000);
    self.coinsInterval = setInterval(() => {
      self.fetchCoinsData()
    },60000);
  },
  beforeDestroy(){
    this.newsInterval.clearInterval();
    this.coinsInterval.clearInterval();
  },
  components: {
    EasyModeToggle,
    NewsItem,
  },
  props: {
    allCoins: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      newsInterval: undefined,
      coinsInterval: undefined,
      sentimentOptions: ["Short-Term", "Long-Term"],
      selectedSentiment: "Short-Term",
      easyMode: false,
      newsItems: [],
      coins: [],
      easyModeOffConfig: [
        { id: 'topGainers', title: 'Top 5 Price Gainers', sortOrder: "DESC" },
        { id: 'topElders', title: 'Top Elder', sortOrder: "DESC" },
        { id: 'topAAScores', title: 'Top 5 AA Score', sortOrder: "DESC" },
        { id: 'topSentiments', title: 'Top 5 Sentiment', sortOrder: "DESC" }
      ],
      topOpportunityColumns: {
        top_opportunity: { name: "Top Opportunity", align: "text-left" },
        price: { name: "Price" , align: "text-right", margin:"ml-4"},
        score: { name: "AA Score™", align: "text-right", margin:"ml-14"},
        elder_impulse_signal_hourly: { name: "Elder 1Hr", align: "text-right", margin:"ml-14"},
        elder_impulse_signal: { name: "Elder Daily", align: "text-right", margin:"ml-4"},
        short_term_sentiment: { name: "Short Term Sentiment", align: "text-right" },
      },
    };
  },
  computed: {
    topOpportunities(){
      const self = this
      let coins = [...self.coins]
      return coins.sort((a,b) => {
        const scoreOfA = self.getTopOpportunityScore(a)
        const scoreOfB = self.getTopOpportunityScore(b)
        if(self.topOpportunitiesSortOrder === "ASC"){
          const diff = scoreOfA - scoreOfB
          return diff == 0 ? a.score - b.score : diff
        }else{
          const diff = scoreOfB - scoreOfA
          return diff == 0 ? b.score - a.score : diff
        }
      }).slice(0,10)
    },
    top5PriceGainers() {
      const self = this
      let coins = [...self.coins]
      let sortOrder = self.easyModeOffConfig.find(x => x.id == "topGainers").sortOrder
      return coins
        .sort((a, b) => {
          if(sortOrder === "ASC"){
            return a.price_change_usd_24hr - b.price_change_usd_24hr
          }else{
            return b.price_change_usd_24hr - a.price_change_usd_24hr
          }
        })
        .slice(0, 5);
    },
    top5Elders() {
      const self = this
      let coins = [...self.coins]
      let sortOrder = self.easyModeOffConfig.find(x => x.id == "topElders").sortOrder
      return coins.filter((coin) => {
        if(self.selectedSentiment === 'Short-Term'){
          if(sortOrder === 'ASC' && coin.elder_impulse_signal_hourly < 0){
              return coin
            } else if(sortOrder === 'DESC' && coin.elder_impulse_signal_hourly > 0){
              return coin
            }
          }else {
            if(sortOrder === 'ASC' && coin.elder_impulse_signal < 0){
              return coin
            }else if(sortOrder === 'DESC' && coin.elder_impulse_signal > 0){
              return coin
            }
          }
        })
    },
    top5AA() {
      const self = this
      let coins = [...self.coins]
      let sortOrder = self.easyModeOffConfig.find(x => x.id == "topAAScores").sortOrder
      return coins
        .filter(x => x.score)
        .sort((a, b) => {
          if(sortOrder === "ASC"){
            return a.score - b.score
          }else{
            return b.score - a.score
          }
        })
        .slice(0, 5);
    },
    top5Sentiments() {
      const self = this
      let coins = [...self.coins]
      let sortOrder = self.easyModeOffConfig.find(x => x.id == "topSentiments").sortOrder
      return coins
        .sort((a, b) => {
          if(sortOrder === "ASC"){
            if(self.selectedSentiment === 'Short-Term'){
              return a.short_term_sentiment - b.short_term_sentiment
            }else{
              return a.long_term_sentiment - b.long_term_sentiment
            }
          }else{
            if(self.selectedSentiment === 'Short-Term'){
              return b.short_term_sentiment - a.short_term_sentiment
            }else{
              return b.long_term_sentiment - a.long_term_sentiment
            }
          }
        }).filter(x => x.tweet_volume > 12).slice(0, 5);
    },
  },
  methods: {
    setEasyMode(easyModeValue) {
       this.easyMode = easyModeValue
       localStorage.setItem('easyMode', this.easyMode);
    },
    fetchNewsItems() {
      axios
        .get("/news")
        .then((response) => {
          this.newsItems = response.data.slice(0, 4);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    fetchCoinsData() {
      axios
        .get("/coins")
        .then((response) => {
          this.coins = JSON.parse(JSON.stringify(response.data));
        })
        .catch((error) => {
          console.log(error);
        });
    },
    toggleSortOrder(id, order){
      let configIndex = this.easyModeOffConfig.findIndex(x => x.id == id)
      let title = this.easyModeOffConfig[configIndex].title
      if(order === "DESC"){
        this.easyModeOffConfig[configIndex].title = title.replace("Top", "Bottom")
        this.easyModeOffConfig[configIndex].sortOrder = "ASC"
      }
      if(order === "ASC"){
        this.easyModeOffConfig[configIndex].title = title.replace("Bottom", "Top")
        this.easyModeOffConfig[configIndex].sortOrder = "DESC"
      }
    },
    getComputedData(item){
      if(item == "topGainers"){
        return this.top5PriceGainers
      }
      if(item == "topElders"){
        return this.top5Elders
      }
      if(item == "topAAScores"){
        return this.top5AA
      }
      if(item == "topSentiments"){
        return this.top5Sentiments
      }
    },
    getTopOpportunityScore(coin){
      let score = 0;
      if (coin.short_term_sentiment >= 60) {
        score += 1
      }
      if (coin.elder_impulse_signal == 1) {
        score += 1
      }
      if (coin.elder_impulse_signal_hourly == 1) {
        score += 1
      }
      if(coin.score >= 60){
        score += 1
      }
      if(coin.score >= 80){
        score += 1
      }
      return score;
    }
  },
};
</script>
